import React from 'react'
import { render } from 'react-dom'
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider'
import { MuiPickersUtilsProvider } from 'material-ui-pickers'
import MomentUtils from '@date-io/moment'
import { Provider } from 'react-redux'
import AppRouter from 'routers/AppRouter'
import { ThemeProvider } from 'styled-components'
import materialTheme from 'theme/material'
import theme from 'theme'
import whiteLabel from 'theme/whiteLabel'
import injectGlobal from 'assets/styles/global'
import authActions from 'states/auth/action'
import store, { history } from './store'
import { getWhitelabel, setPageIcon } from 'utils/whitelabel'

const checkAuth = () => {
  const token = localStorage.getItem('token')
  token && store.dispatch(authActions.authenticate())
}


const App = () => {
  injectGlobal(theme)
  checkAuth()

  const whitelabel = getWhitelabel()
  setPageIcon()
  whiteLabel.init(whitelabel)
  const trueTheme = materialTheme(theme)

  return (
    <Provider store={store}>
      <MuiThemeProvider theme={trueTheme}>
        <ThemeProvider theme={trueTheme}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <AppRouter history={history} />
          </MuiPickersUtilsProvider>
        </ThemeProvider>
      </MuiThemeProvider>
    </Provider>
  )
}


render(<App />, document.getElementById('app'))
export { store, App }
