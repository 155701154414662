import API from 'api'

export const actionTypes = {
    SHOW_TOAST: 'TOAST/SHOW_TOAST',
    SET_MESSAGE: 'TOAST/SET_MESSAGE'
}

export const advertiserActions = {
    showToast: (message = "", duration = 3000) => ({ type: actionTypes.SHOW_TOAST, data: { message, duration } })
}

export default advertiserActions
