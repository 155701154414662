import { createReducer } from '../../reducers'
import { actionTypes } from './action'

export const initialState = () => ({
  loadingCards: false,
  cards: {},
  loadingGraphics: false,
  graphics: {}
})

export const reducer = {
  [actionTypes.SET_CARDS_LOADING](state, { value }) {
    return { ...state, loadingCards: value }
  },
  [actionTypes.SET_CARDS](state, { data }) {
    return { ...state, cards: data }
  },
  [actionTypes.SET_GRAPHICS_LOADING](state, { value }) {
    return { ...state, loadingGraphics: value }
  },
  [actionTypes.SET_GRAPHICS](state, { data }) {
    return { ...state, graphics: data }
  },
  [actionTypes.INITIAL_STATE]() {
    return initialState()
  }
}

export default createReducer(initialState(), reducer)
