const MAX_GUTTER = 100
const spacer = 5
const sides = ['top', 'right', 'bottom', 'left']

let gutterSpace = ''
for (let gutter = 0; gutter <= MAX_GUTTER; gutter += spacer) {
  gutterSpace += `
		.pd-${gutter}{
				padding: ${gutter}px;
		}

		.pd-vertical-${gutter}{
			padding-top: ${gutter}px;
			padding-bottom: ${gutter}px;
		}

		.pd-horizontal-${gutter}{
			padding-left: ${gutter}px;
			padding-right: ${gutter}px;
		}

		.mg-${gutter}{
			margin: ${gutter}px;
		}

		.mg-vertical-${gutter}{
			margin-top: ${gutter}px;
			margin-bottom: ${gutter}px;
		}

		.mg-horizontal-${gutter}{
			margin-left: ${gutter}px;
			margin-right: ${gutter}px;
		}
	`
  sides.forEach(item => {
    gutterSpace += `
			.pd-${item}-${gutter}{
				padding-${item}: ${gutter}px;
			}

			.mg-${item}-${gutter}{
				margin-${item}: ${gutter}px;
			}
		`
  })
}

export default gutterSpace
