import API from 'api'
import toastActions from 'states/toast/action'
import * as S3 from 'aws-sdk/clients/s3'
import { push } from 'react-router-redux'

const albumBucketName = 'nexflow-assets'
const region = 'sa-east-1'

const s3 = new S3({
  apiVersion: '2006-03-01',
  region,
  secretAccessKey: '7lrdjI6XZsn7iDhB+ss9kgVcGw2fMgDcBS9fL8WT',
  accessKeyId: 'AKIAWG7FKT4V23IVM4V6',
  params: { Bucket: albumBucketName }
})

export const actionTypes = {
  SET_LOADING: 'EVENT/SET_LOADING',
  SET_LOADING_DOWNLOAD: 'EVENT/SET_LOADING_DOWNLOAD',
  SET_LOADING_CONVERSATIONS: 'EVENT/SET_LOADING_CONVERSATIONS',
  SET_PUBLISHING: 'EVENT/SET_PUBLISHING',
  SET_SAVING: 'EVENT/SET_SAVING',
  SET_DIALOG_LOADING: 'EVENT/SET_DIALOG_LOADING',
  SET_EVENTS: 'EVENT/SET_EVENTS',
  SET_EVENT: 'EVENT/SET_EVENT',
  SET_VALIDATION: 'EVENT/SET_VALIDATION',
  OPEN_ADD_EVENT: 'EVENT/OPEN_ADD_EVENT',
  OPEN_EDIT_EVENT: 'EVENT/OPEN_EDIT_EVENT',
  OPEN_ADD_USER_CONVERSATIONS: 'EVENT/OPEN_ADD_USER_CONVERSATIONS',
  OPEN_ADD_USER_IMPORT: 'EVENT/OPEN_ADD_USER_IMPORT',
  OPEN_ADD_USER_MANUAL: 'EVENT/OPEN_ADD_USER_MANUAL',
  OPEN_UPLOAD: 'EVENT/OPEN_UPLOAD',
  SET_REPORT: 'EVENT/SET_REPORT',
  SET_CONVERSATIONS_BY_CHANNEL: 'EVENT/SET_CONVERSATIONS_BY_CHANNEL',
  INITIAL_STATE: 'EVENT/INITIAL_STATE'
}

export const actions = {
  getEvents: (search = '', startDate = new Date(), endDate = new Date(), page = 0, limit = 10, sort) => async (dispatch, getState) => {
    const { environment, bot } = getState()
    const botId = bot.bot._id
    const environmentId = environment.environment._id
    dispatch(actions.setLoading(true))
    try {
      const { data } = await API.getEvents(botId, environmentId, search, startDate, endDate, page + 1, limit, sort)
      dispatch(actions.setEvents(data))
    } catch (error) {
    } finally {
      dispatch(actions.setLoading(false))
    }
  },
  getEvent: id => async (dispatch, getState) => {
    const { environment, bot } = getState()
    const botId = bot.bot._id
    const environmentId = environment.environment._id
    try {
      const { data } = await API.getEvent(botId, environmentId, id)
      dispatch(actions.setEvent(data))
      dispatch(actions.getReport(data._id))
    } catch (error) {
    } finally {
    }
  },
  downloadFile: (startDate, endDate) => async (dispatch, getState) => {
    const { environment, bot } = getState()
    const botId = bot.bot._id
    const environmentId = environment.environment._id
    dispatch(actions.setLoadingDownload(true))
    try {
      const { data } = await API.downloadEvents(botId, environmentId, startDate, endDate)
      const url = window.URL.createObjectURL(new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      }))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'NexFlow - Eventos')
      document.body.appendChild(link)
      link.click()
    } catch (error) {
      if (error.response) {
        dispatch(toastActions.showToast(error.response.data.message))
      }
    } finally {
      dispatch(actions.setLoadingDownload(false))
    }
  },
  setLoadingDownload: value => ({ type: actionTypes.SET_LOADING_DOWNLOAD, value }),
  setEvents: data => ({ type: actionTypes.SET_EVENTS, data }),
  setEvent: data => ({ type: actionTypes.SET_EVENT, data }),
  setLoading: value => ({ type: actionTypes.SET_LOADING, value }),
  setLoadingConversations: value => ({ type: actionTypes.SET_LOADING_CONVERSATIONS, value }),
  setSaving: value => ({ type: actionTypes.SET_SAVING, value }),
  setPublishing: value => ({ type: actionTypes.SET_PUBLISHING, value }),
  setDialogLoading: value => ({ type: actionTypes.SET_DIALOG_LOADING, value }),
  initialState: () => (dispatch) => {
    dispatch({ type: actionTypes.INITIAL_STATE })
  }
}

export default actions
