import API from 'api'
import environmentActions from 'states/environment/action'
import toastActions from 'states/toast/action'

export const actionTypes = {
  SET_LOADING: 'VARIABLE/SET_LOADING',
  OPEN_ADD_VARIABLE: 'VARIABLE/OPEN_ADD_VARIABLE',
  OPEN_EDIT_VARIABLE: 'VARIABLE/OPEN_EDIT_VARIABLE',
  SET_EDIT_VARIABLE: 'VARIABLE/SET_EDIT_VARIABLE',
  SET_DIALOG_LOADING: 'VARIABLE/SET_DIALOG_LOADING'
}

export const actions = {
  updateVariable: body => async (dispatch, getState) => {
    const { environment, bot } = getState()
    const botId = bot.bot._id
    const environmentId = environment.environment._id
    dispatch(actions.setLoading(true))
    try {
      const { data } = await API.updateVariable(botId, environmentId, body)
      dispatch(environmentActions.setEnvironment(data))
      dispatch(toastActions.showToast('Variables updated successfully.'))
    } catch (error) {

    } finally {
      dispatch(actions.setLoading(false))
    }
  },
  addVariable: body => async (dispatch, getState) => {
    const { environment, bot } = getState()
    const botId = bot.bot._id
    const environmentId = environment.environment._id
    dispatch(actions.setDialogLoading(true))
    try {
      const { data } = await API.addVariable(botId, environmentId, body)
      dispatch(environmentActions.setEnvironment(data))
      dispatch(actions.openAddVariable(false))
      dispatch(toastActions.showToast('Variable added successfully.'))
    } catch (error) {
    } finally {
      dispatch(actions.setDialogLoading(false))
    }
  },
  editVariable: (id, body) => async (dispatch, getState) => {
    const { environment, bot } = getState()
    const botId = bot.bot._id
    const environmentId = environment.environment._id
    dispatch(actions.setDialogLoading(true))
    try {
      const { data } = await API.editVariable(botId, environmentId, id, body)
      dispatch(environmentActions.setEnvironment(data))
      dispatch(actions.openEditVariable(false))
      dispatch(toastActions.showToast('Variable edited successfully.'))
    } catch (error) {
    } finally {
      dispatch(actions.setDialogLoading(false))
    }
  },
  editVariableDialog: variable => async (dispatch, getState) => {
    dispatch(actions.setEditVariable(variable))
    dispatch(actions.openEditVariable(true))
  },
  removeVariable: variableId => async (dispatch, getState) => {
    const { environment, bot } = getState()
    const botId = bot.bot._id
    const environmentId = environment.environment._id
    try {
      const { data } = await API.removeVariable(botId, environmentId, variableId)
      dispatch(environmentActions.setEnvironment(data))
      dispatch(toastActions.showToast('Variable removed successfully.'))
    } catch (error) {
    } finally {
    }
  },
  setDialogLoading: value => ({ type: actionTypes.SET_DIALOG_LOADING, value }),
  openAddVariable: value => ({ type: actionTypes.OPEN_ADD_VARIABLE, value }),
  openEditVariable: value => ({ type: actionTypes.OPEN_EDIT_VARIABLE, value }),
  setEditVariable: value => ({ type: actionTypes.SET_EDIT_VARIABLE, value }),
  setLoading: value => ({ type: actionTypes.SET_LOADING, value })
}

export default actions
