import { createReducer } from '../../reducers'
import { actionTypes } from './action'

export const initialState = () => ({
  loading: false,
  saving: false,
  publishing: false,
  dialogLoading: false,
  loadingDownload: false,
  loadingConversations: false,
  events: [],
  count: 0,
  event: null,
  openUpload: false,
  openAddEvent: false,
  openEditEvent: false,
  validation: {},
  report: {},
  conversationsByChannel: []
})

export const reducer = {
  [actionTypes.SET_LOADING](state, { value }) {
    return { ...state, loading: value }
  },
  [actionTypes.SET_LOADING_DOWNLOAD](state, { value }) {
    return { ...state, loadingDownload: value }
  },
  [actionTypes.SET_EVENTS](state, { data }) {
    return { ...state, events: data.rows, count: data.count }
  },
  [actionTypes.SET_EVENT](state, { data }) {
    return { ...state, event: data }
  },
  [actionTypes.INITIAL_STATE]() {
    return initialState()
  }
}

export default createReducer(initialState(), reducer)
