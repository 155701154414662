import { createReducer } from '../../reducers'
import { actionTypes } from './action'

export const initialState = () => ({
  count: 0,
  countMessages: 0,
  conversations: [],
  hasOlderMessages: false,
  conversation: null,
  messages: [],
  openModalConversation: false,
  loading: false,
  loadingMessages: false,
  loadingOldMessages: false
})

export const reducer = {
  [actionTypes.SET_LOADING_CONVERSATIONS](state, { value }) {
    return { ...state, loading: value }
  },
  [actionTypes.SET_LOADING_MESSAGES](state, { value }) {
    return { ...state, loadingMessages: value }
  },
  [actionTypes.SET_LOADING_OLD_MESSAGES](state, { value }) {
    return { ...state, loadingOldMessages: value }
  },
  [actionTypes.SET_CONVERSATION](state, { data }) {
    return { ...state, conversation: data, messages: [] }
  },
  [actionTypes.SET_CONVERSATIONS](state, { data }) {
    return { ...state, conversations: data.rows, count: data.count }
  },
  [actionTypes.SET_MESSAGES](state, { data }) {
    return {
      ...state,
      messages: data.rows,
      countMessages: data.count,
      hasOlderMessages: data.hasOlderMessages
    }
  },
  [actionTypes.SET_OPEN_MODAL_CONVERSATION](state, { value }) {
    return { ...state, openModalConversation: value }
  },
  [actionTypes.INITIAL_STATE]() {
    return initialState()
  }
}

export default createReducer(initialState(), reducer)
