import I18n from 'i18n-js'
import en from './en-US'
import pt from './pt-BR'

const normalizeTranslate = {
  en_US: 'en_US',
  pt_BR: 'pt_BR'
}

I18n.translations = {
  en_US: en,
  pt_BR: pt
}

I18n.defaultSeparator = '_'

export const setLanguageToI18n = (language) => {
  const translateNormalize = normalizeTranslate[language]
  const iHaveThisLanguage = I18n.translations.hasOwnProperty(translateNormalize)
  iHaveThisLanguage
    ? I18n.locale = translateNormalize
    : I18n.locale = 'en_US'
}

export const changeLanguage = (language) => {
  localStorage.setItem('language', language)
  const translateNormalize = normalizeTranslate[language]
  const iHaveThisLanguage = I18n.translations.hasOwnProperty(translateNormalize)
  iHaveThisLanguage
    ? I18n.locale = translateNormalize
    : I18n.locale = 'en_US'
  window.location.reload()
}

const language = localStorage.getItem('language') || 'pt_BR'
setLanguageToI18n(language)

export const translate = key => I18n.t(key, {
  defaultValue: key,
  interpolation: {
    escapeValue: false
  }
})
