import API from 'api'
import toastActions from 'states/toast/action'

export const actionTypes = {
  SET_LOADING_CONVERSATIONS: 'TRANSCRIPTOR/SET_LOADING_CONVERSATIONS',
  SET_LOADING_MESSAGES: 'TRANSCRIPTOR/SET_LOADING_MESSAGES',
  SET_LOADING_OLD_MESSAGES: 'TRANSCRIPTOR/SET_LOADING_OLD_MESSAGES',
  SET_LOADING_HANDOVER: 'TRANSCRIPTOR/SET_LOADING_HANDOVER',
  SET_LOADING_EDIT_USER: 'SET_LOADING_EDIT_USER',
  OPEN_LOGS: 'TRANSCRIPTOR/OPEN_LOGS',
  OPEN_HANDOVER: 'TRANSCRIPTOR/OPEN_HANDOVER',
  SET_CONVERSATION: 'TRANSCRIPTOR/SET_CONVERSATION',
  SET_MESSAGES: 'TRANSCRIPTOR/SET_MESSAGES',
  SET_LOGS: 'TRANSCRIPTOR/SET_LOGS',
  SET_HANDOVER: 'TRANSCRIPTOR/SET_HANDOVER',
  SET_CONVERSATIONS: 'TRANSCRIPTOR/SET_CONVERSATIONS',
  SET_CONVERSATIONS_BY_CHANNEL: 'TRANSCRIPTOR/SET_CONVERSATIONS_BY_CHANNEL',
  INITIAL_STATE: 'TRANSCRIPTOR/INITIAL_STATE'
}

export const actions = {
  getConversations: (search = '', filter = '', page = 0, limit = 25) => async (dispatch, getState) => {
    const { environment, bot } = getState()
    const botId = bot.bot._id
    const environmentId = environment.environment._id
    dispatch(actions.setLoadingConversations(true))
    try {
      const { data } = await API.getConversations(botId, environmentId, search, filter, page + 1, limit)
      dispatch(actions.setConversations(data))
    } catch (error) { } finally {
      dispatch(actions.setLoadingConversations(false))
    }
  },
  getConversationsByChannel: (search = '', page = 0, limit = 100) => async (dispatch, getState) => {
    const { environment, bot } = getState()
    const botId = bot.bot._id
    const environmentId = environment.environment._id
    dispatch(actions.setLoadingConversations(true))
    try {
      const { data } = await API.getConversationsByChannel(botId, environmentId)
      dispatch(actions.setConversationsByChannel(data))
    } catch (error) { } finally {
      dispatch(actions.setLoadingConversations(false))
    }
  },
  getMessages: (search = '', page = 0, limit = 30) => async (dispatch, getState) => {
    const { environment, bot, transcriptor } = getState()
    const botId = bot.bot._id
    const environmentId = environment.environment._id
    const conversation = transcriptor?.conversation?.identifier

    if (transcriptor.messages?.length) {
      dispatch(actions.getNewMessages())
      return
    }

    dispatch(actions.setLoadingMessages(true))
    try {
      const { data } = await API.getMessages(botId, environmentId, conversation, search, page + 1, limit)
      data.rows.reverse()
      dispatch(actions.setMessages({ ...data, hasOlderMessages: data.count > data.rows.length }))
    } catch (error) { } finally {
      dispatch(actions.setLoadingMessages(false))
    }
  },
  getNewMessages: (search = '', page = 0, limit = 20) => async (dispatch, getState) => {
    const { environment, bot, transcriptor } = getState()
    const botId = bot.bot._id
    const environmentId = environment.environment._id
    const conversation = transcriptor.conversation.identifier

    try {
      const lastMessage = [...transcriptor.messages].pop()
      const { data } = await API.getMessages(botId, environmentId, conversation, `after=${lastMessage.createdAt}`, 1, 100)
      data.rows.reverse()
      const messages = transcriptor.messages.concat(data.rows)
      dispatch(actions.setMessages({ rows: messages }))
    } catch (error) { }
  },
  getOldMessages: (limit = 50) => async (dispatch, getState) => {
    const { environment, bot, transcriptor } = getState()
    const botId = bot.bot._id
    const environmentId = environment.environment._id
    const conversation = transcriptor.conversation.identifier

    dispatch(actions.setLoadingOldMessages(true))
    try {
      const lastMessage = [...transcriptor.messages][0]
      const { data } = await API.getMessages(botId, environmentId, conversation, `before=${lastMessage.createdAt}`, 1, limit)
      data.rows.reverse()
      const messages = [...data.rows, ...transcriptor.messages]
      dispatch(actions.setMessages({ rows: messages, hasOlderMessages: data.rows.length > 0 }))
    } catch (error) { } finally {
      dispatch(actions.setLoadingOldMessages(false))
    }
  },
  getLogs: log => async (dispatch, getState) => {
    const { environment, bot, transcriptor } = getState()
    const botId = bot.bot._id
    const environmentId = environment.environment._id
    const conversation = transcriptor.conversation.identifier

    console.log('log', log)
    try {
      const { data } = await API.getLogs(botId, environmentId, log.messageId, log.id)
      dispatch(actions.setLogs(data))
    } catch (error) {
    } finally { }
  },
  handOver: category => async (dispatch, getState) => {
    const { environment, bot, transcriptor } = getState()
    const botId = bot.bot._id
    const environmentId = environment.environment._id
    const conversation = transcriptor.conversation

    dispatch(actions.setLoadingHandOver(true))
    try {
      const data = await API.handOver(botId, environmentId, {
        category,
        conversation
      })
      conversation.handOver = data.handOver
      dispatch({ type: actionTypes.SET_HANDOVER, data: conversation })
      dispatch(actions.openHandOver(false))

    } catch (error) { } finally {
      dispatch(actions.setLoadingHandOver(false))
    }
  },
  updateUser: ({ id, user }) => async (dispatch, getState) => {
    dispatch(actions.setLoadingUserEdit(true))
    const { environment, bot, transcriptor } = getState()
    const botId = bot.bot._id
    const environmentId = environment.environment._id
    const conversation = transcriptor.conversation

    try {
      const data = await API.updateUserVariables(botId, environmentId, {
        conversation: id,
        user
      })

      conversation.handOver = data.handOver
      dispatch({ type: actionTypes.SET_HANDOVER, data: { ...conversation, user } })
      dispatch(toastActions.showToast('User updated successfully'))

      // TODO
    } catch (error) {
      dispatch(toastActions.showToast('User not updated'))
    } finally {
      dispatch(actions.setLoadingUserEdit(false))
    }
  },
  updateMessage: data => async (dispatch, getState) => {
    const { transcriptor } = getState()
    const { messages } = transcriptor
    for (const index in messages) {
      if (messages[index].id === data.id) {
        messages[index] = data
        break
      }
    }

    dispatch(actions.setMessages({ rows: [...messages] }))
  },
  setMessages: data => ({ type: actionTypes.SET_MESSAGES, data }),
  setLogs: data => ({ type: actionTypes.SET_LOGS, data }),
  setConversation: data => (dispatch) => {
    dispatch({ type: actionTypes.SET_CONVERSATION, data })
    dispatch(actions.getMessages())
  },
  setConversations: data => ({ type: actionTypes.SET_CONVERSATIONS, data }),
  setConversationsByChannel: data => ({ type: actionTypes.SET_CONVERSATIONS_BY_CHANNEL, data }),
  openLogs: value => async (dispatch) => {
    dispatch({ type: actionTypes.OPEN_LOGS, value })
    if (value) {
      dispatch(actions.getLogs(value))
    } else {
      dispatch(actions.setLogs([]))
    }
  },
  openHandOver: value => ({ type: actionTypes.OPEN_HANDOVER, value }),
  setLoadingConversations: value => ({ type: actionTypes.SET_LOADING_CONVERSATIONS, value }),
  setLoadingMessages: value => ({ type: actionTypes.SET_LOADING_MESSAGES, value }),
  setLoadingOldMessages: value => ({ type: actionTypes.SET_LOADING_OLD_MESSAGES, value }),
  setLoadingHandOver: value => ({ type: actionTypes.SET_LOADING_HANDOVER, value }),
  setLoadingUserEdit: value => ({ type: actionTypes.SET_LOADING_EDIT_USER, value }),
  initialState: () => (dispatch) => {
    dispatch({ type: actionTypes.INITIAL_STATE })
  }
}

export default actions
