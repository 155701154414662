import API from 'api'
import toastActions from 'states/toast/action'

export const actionTypes = {
  SET_CARDS_LOADING: 'DASHBOARD/SET_CARDS_LOADING',
  SET_CARDS: 'DASHBOARD/SET_CARDS',
  SET_GRAPHICS_LOADING: 'DASHBOARD/SET_GRAPHICS_LOADING',
  SET_GRAPHICS: 'DASHBOARD/SET_GRAPHICS',
  INITIAL_STATE: 'DASHBOARD/INITIAL_STATE'
}

export const actions = {
  // getReport: () => async (dispatch, getState) => {
  //   const { environment, bot } = getState()
  //   const botId = bot.bot._id
  //   const environmentId = environment.environment._id
  //   dispatch(actions.setLoading(true))
  //   try {
  //     const endDate = new Date()
  //     const timezoneOffset = endDate.getTimezoneOffset()
  //     const { data } = await API.getDashboard(botId, environmentId, timezoneOffset, endDate)
  //     dispatch(actions.setDashboard(data))
  //   } catch (error) {
  //     const message = error.response ?.data ?.message || 'Unexpected error'
  //     dispatch(toastActions.showToast(message))
  //   } finally {
  //     dispatch(actions.setLoading(false))
  //   }
  // },
  // setDashboard: data => ({ type: actionTypes.SET_DASHBOARD, data }),
  // setLoading: value => ({ type: actionTypes.SET_LOADING, value })
  getCardsDashboard: (startDate, endDate) => async (dispatch, getState) => {
    const { environment, bot } = getState()
    const botId = bot.bot._id
    const environmentId = environment.environment._id
    dispatch(actions.setCardsLoading(true))
    try {
      const { data } = await API.getCardsDashboard(botId, environmentId, startDate, endDate)
      dispatch(actions.setCards(data))
    } catch (error) {
      console.log("dei erro: ", error)
      if (error.response) {
        dispatch(toastActions.showToast(error.response.data.message))
      }
    } finally {
      dispatch(actions.setCardsLoading(false))
    }
  },
  getGraphics: (startDate, endDate) => async (dispatch, getState) => {
    const { environment, bot } = getState()
    const botId = bot.bot._id
    const environmentId = environment.environment._id
    dispatch(actions.setGraphicsLoading(true))
    try {
      const { data } = await API.getGraphics(botId, environmentId, startDate, endDate)
      dispatch(actions.setGraphics(data))
    } catch (error) {
      console.log("dei erro: ", error)
      if (error.response) {
        dispatch(toastActions.showToast(error.response.data.message))
      }
    } finally {
      dispatch(actions.setGraphicsLoading(false))
    }
  },
  setCards: data => ({ type: actionTypes.SET_CARDS, data }),
  setCardsLoading: value => ({ type: actionTypes.SET_CARDS_LOADING, value }),
  setGraphics: data => ({ type: actionTypes.SET_GRAPHICS, data }),
  setGraphicsLoading: value => ({ type: actionTypes.SET_GRAPHICS_LOADING, value }),
  initialState: () => (dispatch) => {
    dispatch({ type: actionTypes.INITIAL_STATE })
  }
}

export default actions
