import { createReducer } from '../../reducers'
import { actionTypes } from './action'

export const initialState = () => ({
  loading: false,
  dialogLoading: false,
  environment: null,
  environments: [],
  openAddEnvironment: false
})

export const reducer = {
  [actionTypes.SET_LOADING](state, { value }) {
    return { ...state, loading: value }
  },
  [actionTypes.SET_DIALOG_LOADING](state, { value }) {
    return { ...state, dialogLoading: value }
  },
  [actionTypes.OPEN_ADD_ENVIRONMENT](state, { value }) {
    return { ...state, openAddEnvironment: value }
  },
  [actionTypes.OPEN_ENVIRONMENT_SETTINGS](state, { value }) {
    return { ...state, openEnvironmentSettings: value }
  },

  [actionTypes.SET_ENVIRONMENT](state, { data }) {
    sessionStorage.setItem('environmentId', data._id)
    return { ...state, environment: data }
  },
  [actionTypes.SET_ENVIRONMENTS](state, { data }) {
    if (!state.environment) {
      const environmentId = sessionStorage.getItem('environmentId')
      state.environment = data.rows.find(item => item._id === environmentId) || data.rows.find(item => item.default)
    }
    return { ...state, environments: data.rows }
  },
  [actionTypes.INITIAL_STATE]() {
    return initialState()
  }
}

export default createReducer(initialState, reducer)
