import API from 'api'
import { replace } from 'react-router-redux'
import botActions from 'states/bot/action'
import toastActions from 'states/toast/action'

export const actionTypes = {
  GET_ME: 'USER/GET_ME',
  SET_USER: 'USER/SET_USER',
  SET_TENANT: 'USER/SET_TENANT',
  SET_LOADING: 'USER/SET_LOADING',
  SET_DIALOG_LOADING: 'USER/SET_DIALOG_LOADING',
  SET_USER_FB: 'USER/SET_USER_FB',
  INITIAL_STATE: 'USER/INITIAL_STATE'
}

export const actions = {
  me: () => async (dispatch) => {
    dispatch(actions.setLoading(true))
    try {
      const { data } = await API.me()
      dispatch(actions.setUser(data))
    } catch (error) {
      error.response && dispatch(toastActions.showToast(error.response.data.message))
    } finally {
      dispatch(actions.setLoading(false))
    }
  },
  linkFb: fbCredentials => async (dispatch) => {
    try {
      dispatch(actions.setLoading(true))
      const { data } = await API.linkFb({ fbCredentials })
      localStorage.setItem('user', JSON.stringify(data))
      dispatch(actions.setUserFb(data))
      dispatch(toastActions.showToast('Facebook linked successfully.'))
    } catch (error) {
    } finally {
      dispatch(actions.setLoading(false))
    }
  },
  unlinkFb: () => async (dispatch) => {
    try {
      dispatch(actions.setLoading(true))
      const { data } = await API.unlinkFb()
      localStorage.setItem('user', JSON.stringify(data))
      dispatch(actions.setUserFb(data))
      dispatch(toastActions.showToast('Facebook disconnected successfully.'))
    } catch (error) {
    } finally {
      dispatch(actions.setLoading(false))
    }
  },
  setUserFb: (data) => ({ type: actionTypes.SET_USER_FB, data }),
  setUser: (data) => ({ type: actionTypes.SET_USER, data }),
  changeTenant: (tenant) => async (dispatch) => {
    dispatch(actions.setTenant(tenant))
    dispatch(botActions.setBots({ rows: [] }))
    dispatch(botActions.getBots())
    dispatch(botActions.setBotsPaginated({ rows: [], count: 0 }))
    dispatch(botActions.getBotsPaginated())
    dispatch(replace('/bots'))
  },
  setTenant: (tenant) => ({ type: actionTypes.SET_TENANT, tenant }),
  setLoading: (value) => ({ type: actionTypes.SET_LOADING, value }),
  setDialogLoading: (value) => ({ type: actionTypes.SET_DIALOG_LOADING, value }),
  initialState: () => ({ type: actionTypes.INITIAL_STATE })

}

export default actions
