


import API from 'api'
import environmentActions from 'states/environment/action'
import toastActions from 'states/toast/action'

export const actionTypes = {
  SET_LOADING: 'NLP/SET_LOADING',
  SET_DIALOG_LOADING: 'NLP/SET_DIALOG_LOADING',
  INITIAL_STATE: 'NLP/INITIAL_STATE'
}

export const actions = {
  connectNLP: (body) => async (dispatch, getState) => {
    const { environment, bot } = getState()
    const botId = bot.bot._id
    const environmentId = environment.environment._id
    dispatch(actions.setLoading(true))
    try {
      const { data } = await API.connectNLP(botId, environmentId, body)
      dispatch(environmentActions.setEnvironment(data))
      dispatch(toastActions.showToast('NLP connected successfully.'))
    } catch (error) {
      const message = error.response ?.data ?.message || 'Unexpected error'
      dispatch(toastActions.showToast(message))
    } finally {
      dispatch(actions.setLoading(false))
    }
  },
  disconnectNLP: () => async (dispatch, getState) => {
    const { environment, bot } = getState()
    const botId = bot.bot._id
    const environmentId = environment.environment._id
    dispatch(actions.setLoading(true))
    try {
      const { data } = await API.disconnectNLP(botId, environmentId)
      dispatch(environmentActions.setEnvironment(data))
      dispatch(toastActions.showToast('NLP disconnected successfully.'))

    } catch (error) {
    } finally {
      dispatch(actions.setLoading(false))
    }
  },
  setLoading: (value) => ({ type: actionTypes.SET_LOADING, value }),
  setDialogLoading: (value) => ({ type: actionTypes.SET_DIALOG_LOADING, value }),
  initialState: () => (dispatch) => {
    dispatch({ type: actionTypes.INITIAL_STATE })
  }
}

export default actions
