import API from 'api'
import toastActions from 'states/toast/action'

export const actionTypes = {
  SET_LOADING: 'ENTITY/SET_LOADING',
  SET_DIALOG_LOADING: 'ENTITY/SET_DIALOG_LOADING',
  SET_ENTITIES: 'ENTITY/SET_ENTITIES',
  SET_ENTITY: 'ENTITY/SET_ENTITY',
  OPEN_ADD_ENTITY: 'ENTITY/OPEN_ADD_ENTITY',
  OPEN_EDIT_ENTITY: 'ENTITY/OPEN_EDIT_ENTITY',
  SET_ALL_ENTITIES: 'INTENT/SET_ALL_ENTITIES',
  SET_LOADING_ALL: 'INTENT/SET_LOADING_ALL',
  INITIAL_STATE: 'ENTITY/INITIAL_STATE'
}

export const actions = {
  getAllEntities: (search = '') => async (dispatch, getState) => {
    const { environment, bot } = getState()
    const botId = bot?.bot?._id
    const environmentId = environment?.environment?._id
    try {
      const { data } = await API.getAllEntities(botId, environmentId, search)
      dispatch(actions.setAllEntities(data))
    } catch (error) { }
  },
  getEntities: (search = '', page = 0, limit = 10, sort) => async (dispatch, getState) => {
    const { environment, bot } = getState()
    const botId = bot.bot._id
    const environmentId = environment.environment._id
    dispatch(actions.setLoading(true))
    try {
      const { data } = await API.getEntities(botId, environmentId, search, page + 1, limit, sort)
      dispatch(actions.setEntities(data))
    } catch (error) {
    } finally {
      dispatch(actions.setLoading(false))
    }
  },
  addEntity: (body) => async (dispatch, getState) => {
    const { environment, bot } = getState()
    const botId = bot.bot._id
    const environmentId = environment.environment._id
    dispatch(actions.setDialogLoading(true))
    try {
      const { data } = await API.addEntity(botId, environmentId, body)
      dispatch(actions.openAddEntity(false))
      dispatch(actions.getEntities())
      dispatch(toastActions.showToast('Entity added successfully.'))
    } catch (error) {
    } finally {
      dispatch(actions.setDialogLoading(false))
    }
  },
  removeEntity: (id) => async (dispatch, getState) => {
    const { environment, bot } = getState()
    const botId = bot.bot._id
    const environmentId = environment.environment._id
    dispatch(actions.setLoading(true))
    try {
      const { data } = await API.removeEntity(botId, environmentId, id)
      dispatch(actions.getEntities())
      dispatch(toastActions.showToast('Entity removed successfully.'))
    } catch (error) {
    } finally {
      dispatch(actions.setLoading(false))
    }
  },
  getEntity: (id) => async (dispatch, getState) => {
    const { environment, bot } = getState()
    const botId = bot.bot._id
    const environmentId = environment.environment._id
    try {
      const { data } = await API.getEntity(botId, environmentId, id)
      dispatch(actions.setEntity(data))
    } catch (error) {
    } finally {
    }
  },
  updateEntity: (id, body) => async (dispatch, getState) => {
    const { environment, bot } = getState()
    const botId = bot.bot._id
    const environmentId = environment.environment._id
    try {
      dispatch(actions.setDialogLoading(true))
      const { data } = await API.updateEntity(botId, environmentId, id, body)
      dispatch(actions.openEditEntity(false))
      dispatch(actions.getEntities())
      dispatch(toastActions.showToast('Entity updated successfully.'))
    } catch (error) {
    } finally {
      dispatch(actions.setDialogLoading(false))
    }
  },
  setEntities: (data) => ({ type: actionTypes.SET_ENTITIES, data }),
  setAllEntities: (data) => ({ type: actionTypes.SET_ALL_ENTITIES, data }),
  setEntity: (data) => ({ type: actionTypes.SET_ENTITY, data }),
  openAddEntity: (value) => ({ type: actionTypes.OPEN_ADD_ENTITY, value }),
  openEditEntity: (value) => async (dispatch) => {
    dispatch({ type: actionTypes.OPEN_EDIT_ENTITY, value })
    value && dispatch(actions.getEntity(value))
  },
  setLoading: (value) => ({ type: actionTypes.SET_LOADING, value }),
  setDialogLoading: (value) => ({ type: actionTypes.SET_DIALOG_LOADING, value }),
  setLoadingAll: value => ({ type: actionTypes.SET_LOADING_ALL, value }),
  initialState: () => (dispatch) => {
    dispatch({ type: actionTypes.INITIAL_STATE })
  }
}

export default actions
