import API from 'api'
import toastActions from 'states/toast/action'
import * as S3 from 'aws-sdk/clients/s3'
import { push } from 'react-router-redux'
import { translate } from 'locales'

const albumBucketName = 'nexflow-assets'
const region = 'sa-east-1'

const s3 = new S3({
  apiVersion: '2006-03-01',
  region,
  secretAccessKey: '7lrdjI6XZsn7iDhB+ss9kgVcGw2fMgDcBS9fL8WT',
  accessKeyId: 'AKIAWG7FKT4V23IVM4V6',
  params: { Bucket: albumBucketName }
})

export const actionTypes = {
  SET_LOADING: 'BROADCAST/SET_LOADING',
  SET_LOADING_SEND: 'BROADCAST/SET_LOADING_SEND',
  SET_LOADING_BROADCAST: 'BROADCAST/SET_LOADING_BROADCAST',
  SET_LOADING_CONVERSATIONS: 'BROADCAST/SET_LOADING_CONVERSATIONS',
  SET_LOADING_BROADCAST_STATS: 'BROADCAST/SET_LOADING_BROADCAST_STATS',
  SET_PUBLISHING: 'BROADCAST/SET_PUBLISHING',
  SET_SAVING: 'BROADCAST/SET_SAVING',
  SET_DIALOG_LOADING: 'BROADCAST/SET_DIALOG_LOADING',
  SET_BROADCASTS: 'BROADCAST/SET_BROADCASTS',
  SET_BROADCAST_STATS: 'BROADCAST/SET_BROADCAST_STATS',
  SET_BROADCAST: 'BROADCAST/SET_BROADCAST',
  SET_VALIDATION: 'BROADCAST/SET_VALIDATION',
  OPEN_ADD_BROADCAST: 'BROADCAST/OPEN_ADD_BROADCAST',
  OPEN_EDIT_BROADCAST: 'BROADCAST/OPEN_EDIT_BROADCAST',
  OPEN_ADD_USER_CONVERSATIONS: 'BROADCAST/OPEN_ADD_USER_CONVERSATIONS',
  OPEN_ADD_USER_IMPORT: 'BROADCAST/OPEN_ADD_USER_IMPORT',
  OPEN_ADD_USER_MANUAL: 'BROADCAST/OPEN_ADD_USER_MANUAL',
  OPEN_UPLOAD: 'BROADCAST/OPEN_UPLOAD',
  SET_REPORT: 'BROADCAST/SET_REPORT',
  SET_AVAILABLE_USERS: 'BROADCAST/SET_AVAILABLE_USERS',
  SET_CONVERSATIONS_BY_CHANNEL: 'BROADCAST/SET_CONVERSATIONS_BY_CHANNEL',
  SET_USER_FIELDS: 'BROADCAST/SET_USER_FIELDS',
  SET_LOADING_AVAILABLE: 'BROADCAST/SET_LOADING_AVAILABLE',
  SET_LOADING_DOWNLOAD: 'BROADCAST/SET_LOADING_DOWNLOAD',

  SET_REMOVED_LOADING: 'BROADCAST/SET_REMOVED_LOADING',
  SET_REMOVED_BROADCASTS: 'BROADCAST/SET_REMOVED_BROADCASTS',

  INITIAL_STATE: 'BROADCAST/INITIAL_STATE'
}

export const actions = {
  getBroadcasts: (search = '', page = 0, limit = 10, sort) => async (dispatch, getState) => {
    const { environment, bot } = getState()
    const botId = bot.bot._id
    const environmentId = environment.environment._id
    dispatch(actions.setLoading(true))
    try {
      const { data } = await API.getBroadcasts(botId, environmentId, search, page + 1, limit, sort)
      dispatch(actions.setBroadcasts(data))
    } catch (error) {
    } finally {
      dispatch(actions.setLoading(false))
    }
  },
  getRemovedBroadcasts: (search = '', page = 0, limit = 10, sort) => async (dispatch, getState) => {
    const { environment, bot } = getState()
    const botId = bot.bot._id
    const environmentId = environment.environment._id
    dispatch(actions.setRemovedLoading(true))
    try {
      const { data } = await API.getRemovedBroadcasts(botId, environmentId, search, page + 1, limit, sort)
      dispatch(actions.setRemovedBroadcasts(data))
    } catch (error) {
    } finally {
      dispatch(actions.setRemovedLoading(false))
    }
  },
  getBroadcastStats: (id, page = 0, limit = 10, sort) => async (dispatch, getState) => {
    const { environment, bot } = getState()
    const botId = bot.bot._id
    const environmentId = environment.environment._id
    dispatch(actions.setLoadingBroadcastStats(true))
    try {
      const { data } = await API.getBroadcastStats(botId, environmentId, id, page + 1, limit, sort)
      dispatch(actions.setBroadcastStats(data))
    } catch (error) {
    } finally {
      dispatch(actions.setLoadingBroadcastStats(false))
    }
  },
  exportBroadcastStats: (id) => async (dispatch, getState) => {
    const { environment, bot } = getState()
    const botId = bot.bot._id
    const environmentId = environment.environment._id
    dispatch(actions.setLoadingDownload(true))
    try {
      const { data } = await API.exportBroadcastStats(botId, environmentId, id)
      const href = data.url
      const link = document.createElement('a')
      link.href = href

      const fileName = data.url.split('/')
      link.download = fileName[fileName.length - 1]
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    } catch (error) {
      console.log(error)
      if (error.response.status === 404) {
        dispatch(toastActions.showToast(translate('Error to generate excel file')))
      } else {
        dispatch(toastActions.showToast(translate('Error to export data')))
      }
    } finally {
      dispatch(actions.setLoadingDownload(false))
    }
  },
  addBroadcast: body => async (dispatch, getState) => {
    const { environment, bot } = getState()
    const botId = bot.bot._id
    const environmentId = environment.environment._id
    dispatch(actions.setDialogLoading(true))
    try {
      const { data } = await API.addBroadcast(botId, environmentId, body)
      dispatch(actions.openAddBroadcast(false))
      dispatch(actions.getBroadcasts())
      dispatch(push(`/bots/${bot.bot.index.name}/broadcast/${data.index.name}`))
      dispatch(toastActions.showToast(translate('Broadcast added successfully')))
    } catch (error) {
    } finally {
      dispatch(actions.setDialogLoading(false))
    }
  },
  removeBroadcast: id => async (dispatch, getState) => {
    const { environment, bot } = getState()
    const botId = bot.bot._id
    const environmentId = environment.environment._id
    dispatch(actions.setLoading(true))
    try {
      await API.removeBroadcast(botId, environmentId, id)
      dispatch(actions.getBroadcasts())
      dispatch(toastActions.showToast(translate('Broadcast removed successfully')))
    } catch (error) {
      dispatch(toastActions.showToast('Could not remove broadcast.'))
    } finally {
      dispatch(actions.setLoading(false))
    }
  },
  getBroadcast: (id) => async (dispatch, getState) => {
    const { environment, bot } = getState()
    const botId = bot.bot._id
    const environmentId = environment.environment._id
    try {
      dispatch(actions.setLoadingBroadcast(true))

      const { data } = await API.getBroadcast(botId, environmentId, id)
      dispatch(actions.setBroadcast(data))
      dispatch(actions.getAvailableUsers(data))
    } catch (error) {
    } finally {
      dispatch(actions.setLoadingBroadcast(false))

    }
  },
  getAvailableUsers: broadcast => async (dispatch, getState) => {
    try {
      dispatch(actions.setLoadingAvailable(true))
      const { environment, bot } = getState()
      const botId = bot.bot._id
      const environmentId = environment.environment._id
      const { data } = await API.getAvailableUsers(botId, environmentId, broadcast)
      dispatch(actions.setAvailableUsers(data))
    } catch (error) {

    } finally {
      dispatch(actions.setLoadingAvailable(false))
    }

  },
  getReport: id => async (dispatch, getState) => {
    const { environment } = getState()
    const environmentId = environment.environment._id
    try {
      const { data } = await API.getReport(environmentId, id)
      dispatch(actions.setReport(data))
    } catch (error) {
    } finally {
    }
  },
  updateBroadcast: (id, body) => async (dispatch, getState) => {
    const { environment, bot } = getState()
    const botId = bot.bot._id
    const environmentId = environment.environment._id
    try {
      dispatch(actions.setLoading(true))
      const { data } = await API.updateBroadcast(botId, environmentId, id, body)
      dispatch(actions.setBroadcast(data))
      dispatch(toastActions.showToast(translate('Broadcast updated successfully')))
      // dispatch(push(`/bots/${bot.bot.index.name}#broadcasts`))

    } catch (error) {
      console.log(error)
    } finally {
      dispatch(actions.setLoading(false))
    }
  },
  removeUserBroadcast: (id, userId, indexName, page, limit) => async (dispatch, getState) => {
    const { environment, bot } = getState()
    const botId = bot.bot._id
    const environmentId = environment.environment._id
    try {
      dispatch(actions.setLoading(true))
      const { data } = await API.removeUserBroadcast(botId, environmentId, id, userId)
      dispatch(toastActions.showToast('User removed successfully.'))
      dispatch(actions.getBroadcast(indexName, page, limit))
    } catch (error) {
      console.log(error)
    } finally {
      dispatch(actions.setLoading(false))
    }
  },
  updateSendBroadcast: (id, body) => async (dispatch, getState) => {
    const { environment, bot } = getState()
    const botId = bot.bot._id
    const environmentId = environment.environment._id
    try {
      dispatch(actions.setLoadingSend(true))
      const { data } = await API.updateBroadcast(botId, environmentId, id, body)
      await API.sendBroadcast(botId, environmentId, id)
      dispatch(toastActions.showToast(translate('Broadcast updated and sended successfully')))
      dispatch(push(`/bots/${bot.bot.index.name}#broadcasts`))

    } catch (error) {
      console.log(error)
    } finally {
      dispatch(actions.setLoadingSend(false))
    }
  },
  updateBroadcastDiagram: (id, body) => async (dispatch, getState) => {
    const { environment, bot } = getState()
    const botId = bot.bot._id
    const environmentId = environment.environment._id
    try {
      dispatch(actions.setSaving(true))
      const { data } = await API.updateBroadcastDiagram(botId, environmentId, id, body)
      dispatch(actions.setBroadcast(data))
    } catch (error) {
    } finally {
      dispatch(actions.setSaving(false))
    }
  },
  publishBroadcast: id => async (dispatch, getState) => {
    const { environment, bot } = getState()
    const botId = bot.bot._id
    const environmentId = environment.environment._id
    try {
      dispatch(actions.setPublishing(true))
      const { data } = await API.publishBroadcast(botId, environmentId, id)
      dispatch(toastActions.showToast('Broadcast published successfully'))
    } catch (error) {
      const message = error.response ?.data ?.message || 'Unexpected error'
      dispatch(toastActions.showToast(message))
    } finally {
      dispatch(actions.setPublishing(false))
    }
  },
  validateBroadcast: id => async (dispatch, getState) => {
    const { environment, bot } = getState()
    const botId = bot.bot._id
    const environmentId = environment.environment._id
    try {
      const { data } = await API.validateBroadcast(botId, environmentId, id)
      dispatch(actions.setValidation(data))
      dispatch(toastActions.showToast('Broadcast validated successfully'))
    } catch (error) {
      const message = error.response ?.data ?.message || 'Unexpected error'
      dispatch(toastActions.showToast(message))
    } finally {

    }
  },
  getUserFields: () => async (dispatch, getState) => {
    const { environment, bot } = getState()
    const botId = bot.bot._id
    const environmentId = environment.environment._id
    try {
      const { data } = await API.getUserFields(botId, environmentId)
      dispatch(actions.setUserFields(data))
    } catch (error) { }
  },
  setConversationsByChannel: data => ({ type: actionTypes.SET_CONVERSATIONS_BY_CHANNEL, data }),
  addUserConversations: (id, body, indexName) => async (dispatch, getState) => {
    const { environment, bot } = getState()
    const botId = bot.bot._id
    const environmentId = environment.environment._id
    try {
      dispatch(actions.setLoading(true))
      await API.addUserConversationBroadcast(botId, environmentId, id, body)
      dispatch(toastActions.showToast('Broadcast updated successfully.'))
      dispatch(actions.getBroadcast(indexName))
      dispatch(actions.openAddUserConversations(false))
    } catch (error) {
      console.log(error)
    } finally {
      dispatch(actions.setLoading(false))
    }
  },
  addUserManual: (id, body, indexName) => async (dispatch, getState) => {
    const { environment, bot } = getState()
    const botId = bot.bot._id
    const environmentId = environment.environment._id
    try {
      dispatch(actions.setLoading(true))
      await API.addUserBroadcast(botId, environmentId, id, body)
      dispatch(toastActions.showToast(translate('Broadcast updated successfully')))
      dispatch(actions.getBroadcast(indexName))
      dispatch(actions.openAddUserManual(false))
    } catch (error) {
      console.log(error)
    } finally {
      dispatch(actions.setLoading(false))
    }
  },
  importUsers: (id, body, indexName) => async (dispatch, getState) => {
    const { environment, bot } = getState()
    const botId = bot.bot._id
    const environmentId = environment.environment._id
    try {
      dispatch(actions.setLoading(true))
      const ext = body.file.name.split('.').pop()
      const fileKey = `broadcast/import/${new Date().toISOString()}.${ext}`
      await actions.uploadFile(body.file, fileKey)
      body.fileKey = fileKey
      await API.uploadBroadcast(botId, environmentId, id, body)
      dispatch(toastActions.showToast(translate('Broadcast updated successfully')))
      dispatch(actions.getBroadcast(indexName))
      dispatch(actions.openUpload(false))
    } catch (error) {
      console.log(error)
    } finally {
      dispatch(actions.setLoading(false))
    }
  },
  uploadFile: async (file, Key) => {
    const { Location } = await s3.upload({
      Key,
      Body: file,
      ACL: 'public-read'
    }).promise()
    return Location
  },
  uploadBroadcastFile: (file, broadcast) => async (dispatch, getState) => {
    const { environment } = getState()
    const environmentId = environment.environment._id

    const ext = file.name.split('.').pop()
    const fileKey = `broadcast/${environmentId}/${new Date().toISOString()}.${ext}`
    const fileUrl = await actions.uploadFile(file, fileKey)

    dispatch(actions.setBroadcast({ ...broadcast, file: fileUrl }))
    dispatch(toastActions.showToast(translate('Broadcast file uploaded successfully')))
  },
  setBroadcasts: data => ({ type: actionTypes.SET_BROADCASTS, data }),
  setRemovedBroadcasts: data => ({ type: actionTypes.SET_REMOVED_BROADCASTS, data }),
  setBroadcastStats: data => ({ type: actionTypes.SET_BROADCAST_STATS, data }),
  setBroadcast: data => ({ type: actionTypes.SET_BROADCAST, data }),
  setValidation: data => ({ type: actionTypes.SET_VALIDATION, data }),
  setReport: data => ({ type: actionTypes.SET_REPORT, data }),
  openAddBroadcast: value => ({ type: actionTypes.OPEN_ADD_BROADCAST, value }),
  openEditBroadcast: value => async (dispatch) => {
    dispatch({ type: actionTypes.OPEN_EDIT_BROADCAST, value })
    value && dispatch(actions.getBroadcast(value))
  },
  openAddUserConversations: value => ({ type: actionTypes.OPEN_ADD_USER_CONVERSATIONS, value }),
  openAddUserImport: value => ({ type: actionTypes.OPEN_ADD_USER_IMPORT, value }),
  openAddUserManual: value => ({ type: actionTypes.OPEN_ADD_USER_MANUAL, value }),
  openUpload: value => ({ type: actionTypes.OPEN_UPLOAD, value }),
  setLoading: value => ({ type: actionTypes.SET_LOADING, value }),
  setRemovedLoading: value => ({ type: actionTypes.SET_REMOVED_LOADING, value }),
  setAvailableUsers: data => ({ type: actionTypes.SET_AVAILABLE_USERS, data }),
  setLoadingBroadcast: value => ({ type: actionTypes.SET_LOADING_BROADCAST, value }),
  setLoadingBroadcastStats: value => ({ type: actionTypes.SET_LOADING_BROADCAST_STATS, value }),
  setLoadingSend: value => ({ type: actionTypes.SET_LOADING_SEND, value }),
  setLoadingAvailable: value => ({ type: actionTypes.SET_LOADING_AVAILABLE, value }),
  setLoadingDownload: value => ({ type: actionTypes.SET_LOADING_DOWNLOAD, value }),
  setSaving: value => ({ type: actionTypes.SET_SAVING, value }),
  setPublishing: value => ({ type: actionTypes.SET_PUBLISHING, value }),
  setDialogLoading: value => ({ type: actionTypes.SET_DIALOG_LOADING, value }),
  setUserFields: data => ({ type: actionTypes.SET_USER_FIELDS, data }),
  initialState: () => ({ type: actionTypes.INITIAL_STATE })
}

export default actions
