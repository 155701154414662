import { store } from '../'
import authActions from 'states/auth/action'
import API from './'

const diffMinutes = (dt2) => {
    var diff = (new Date(dt2).getTime() - new Date().getTime()) / 1000
    diff /= 60
    return Math.abs(Math.round(diff))
}

export const withInterceptor = (axios) => {
    axios.interceptors.response.use((response) => {
        // Do something with response data
        return response
    }, async (error) => {
        // Do something with response error
        const { response } = error
        if (response) {
            let lastRefresh = Number(localStorage.getItem('refreshAttempt'))

            if (response.status === 401 && localStorage.getItem('refreshToken') && (lastRefresh == null || diffMinutes(lastRefresh) > 5)) {
                localStorage.setItem('refreshAttempt', new Date().getTime())
                store.dispatch(authActions.refreshAuth(true))
                try {
                    const { data } = await API.refreshToken()
                    localStorage.setItem('token', data.token)
                    localStorage.setItem('refreshToken', data.refreshToken)
                    const response = await API.me()
                    error.config.headers.Authorization = data.token
                    return axios.request(error.config)
                } catch (e) {
                    store.dispatch(authActions.logout())
                }
                finally {
                    store.dispatch(authActions.refreshAuth(false))
                }
            } else if (response.status === 401) {
                store.dispatch(authActions.logout())
            }
        }
        return Promise.reject(error)
    })
}

export default withInterceptor