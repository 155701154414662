import axios from 'axios'
import withInterceptor from './interceptor'
import { getWhitelabel } from 'utils/whitelabel'
import { paramsToString } from './helper'

withInterceptor(axios)

const host = `${process.env.REACT_APP_API_URL}`
export const NEXDESK_URL = `${process.env.REACT_APP_NEXDESK_URL}`


const fbHost = 'https://graph.facebook.com/v9.0'

const request = params => axios({
  baseURL: host,
  headers: {
    'Content-Type': 'application/json',
    wl: getWhitelabel()
  },
  ...params
})

const fbRequest = params => axios({
  baseURL: fbHost,
  headers: {
    'Content-Type': 'application/json'
  },
  ...params
})

const authRequest = async params => axios({
  baseURL: host,
  headers: {
    'Content-Type': 'application/json',
    Authorization: `${localStorage.getItem('token')}`,
    Tenant: sessionStorage.getItem('tenantId'),
    wl: getWhitelabel()
  },
  ...params
})

const authRefreshRequest = async params => axios({
  baseURL: host,
  headers: {
    'Content-Type': 'application/json',
    Authorization: `${localStorage.getItem('refreshToken')}`,
    Tenant: sessionStorage.getItem('tenantId'),
    wl: getWhitelabel()
  },
  ...params
})

const runtimeHost = `${process.env.REACT_APP_API_URL}`
const runtimeRequest = params => axios({
  baseURL: runtimeHost,
  headers: {
    'Content-Type': 'application/json'
  },
  ...params
})


export default {
  getPages: accessToken => fbRequest({
    url: `/me/accounts?access_token=${accessToken}`,
    method: 'GET'
  }),
  getPageInfo: (pageId, accessToken) => fbRequest({
    url: `/${pageId}?fields=instagram_business_account&access_token=${accessToken}`,
    method: 'GET'
  }),
  login: (hash, data) => request({
    url: '/auth',
    method: 'POST',
    headers: {
      Authorization: hash,
      wl: getWhitelabel()
    },
    data
  }),
  refreshToken: () => authRefreshRequest({
    url: '/auth/refresh',
    method: 'POST'
  }),
  forgotEmail: data => request({
    url: '/user/forgot',
    method: 'POST',
    data
  }),
  getForgotByToken: token => request({
    url: `/forgot/${token}`,
    method: 'GET'
  }),
  updatePassword: data => request({
    url: '/user/updatePassword',
    method: 'POST',
    data
  }),
  getInviteByToken: token => request({
    url: `/invite/token/${token}`,
    method: 'GET'
  }),
  acceptInvite: (token, data) => request({
    url: `/invite/token/${token}`,
    method: 'POST',
    data
  }),
  me: () => authRequest({
    url: '/user/me',
    method: 'GET'
  }),
  linkFb: data => authRequest({
    url: '/user/fb/link',
    method: 'POST',
    data
  }),
  unlinkFb: () => authRequest({
    url: '/user/fb/unlink',
    method: 'POST'
  }),
  linkGoogle: data => authRequest({
    url: '/user/google/link',
    method: 'POST',
    data
  }),
  unlinkGoogle: () => authRequest({
    url: '/user/google/unlink',
    method: 'POST'
  }),
  createBot: data => authRequest({
    url: '/bot',
    method: 'POST',
    data
  }),
  updateBot: (botId, data) => authRequest({
    url: `/bot/${botId}`,
    method: 'PUT',
    data
  }),
  getBots: (search = '') => authRequest({
    url: `/bot?q=${search}&sort=name`,
    method: 'GET'
  }),
  getBotsPaginated: (search = '', page = 1, limit = 30, sort = '') => authRequest({
    url: `/bot?q=${search}&page=${page}&limit=${limit}&sort=name`,
    method: 'GET'
  }),
  getBotById: id => authRequest({
    url: `/bot/${id}`,
    method: 'GET'
  }),
  removeBot: id => authRequest({
    url: `/bot/${id}`,
    method: 'DELETE'
  }),
  inviteUser: (id, data) => authRequest({
    url: `/bot/${id}/invite`,
    method: 'POST',
    data
  }),
  removeInvite: (id, inviteId) => authRequest({
    url: `/bot/${id}/invite/${inviteId}`,
    method: 'DELETE'
  }),
  getInvites: (id, search = '', page = 1, pageSize = 10, sort) => authRequest({
    url: `/bot/${id}/invite?q=${search}&page=${page}&limit=${pageSize}&sort=${sort}`,
    method: 'GET'
  }),
  getBotUsers: (id, search = '', page = 1, pageSize = 10, sort) => authRequest({
    url: `/bot/${id}/users?q=${search}&page=${page}&limit=${pageSize}&sort=${sort}`,
    method: 'GET'
  }),
  editBotUser: (id, userId, data) => authRequest({
    url: `/bot/${id}/users/${userId}`,
    method: 'PUT',
    data
  }),
  removeBotUser: (id, userId) => authRequest({
    url: `/bot/${id}/users/${userId}`,
    method: 'DELETE'
  }),
  getEnvironments: botId => authRequest({
    url: `/bot/${botId}/environment`,
    method: 'GET'
  }),
  createEnvironment: (botId, data) => authRequest({
    url: `/bot/${botId}/environment`,
    method: 'POST',
    data
  }),
  updateEnvironment: (botId, environmentId, data) => authRequest({
    url: `/bot/${botId}/environment/${environmentId}`,
    method: 'PUT',
    data
  }),
  updateInactivity: (botId, environmentId, data) => authRequest({
    url: `/bot/${botId}/environment/${environmentId}/inactivity`,
    method: 'POST',
    data
  }),
  setDefaultFlow: (botId, environmentId, data) => authRequest({
    url: `/bot/${botId}/environment/${environmentId}/default-flow`,
    method: 'POST',
    data
  }),
  unsetDefaultFlow: (botId, environmentId) => authRequest({
    url: `/bot/${botId}/environment/${environmentId}/default-flow`,
    method: 'DELETE'
  }),
  setLinkFlow: (botId, environmentId, data) => authRequest({
    url: `/bot/${botId}/environment/${environmentId}/link-flow`,
    method: 'POST',
    data
  }),
  unsetLinkFlow: (botId, environmentId) => authRequest({
    url: `/bot/${botId}/environment/${environmentId}/link-flow`,
    method: 'DELETE'
  }),
  connectNLP: (botId, environmentId, data) => authRequest({
    url: `/bot/${botId}/environment/${environmentId}/nlp`,
    method: 'POST',
    data
  }),
  disconnectNLP: (botId, environmentId) => authRequest({
    url: `/bot/${botId}/environment/${environmentId}/nlp`,
    method: 'DELETE'
  }),
  getIntents: (botId, environmentId, search = '', page, limit, sort) => authRequest({
    url: `/bot/${botId}/environment/${environmentId}/intent?q=${search}&page=${page}&limit=${limit}&sort=${sort}`,
    method: 'GET'
  }),
  getUnhandledIntents: (botId, environmentId, search = '', page, limit, sort) => authRequest({
    url: `/bot/${botId}/environment/${environmentId}/intent/unhandled?q=${search}&page=${page}&limit=${limit}&sort=${sort}`,
    method: 'GET'
  }),
  getContextMessages: (botId, environmentId, messageId) => authRequest({
    url: `/bot/${botId}/environment/${environmentId}/intent/${messageId}/context`,
    method: 'GET'
  }),
  ignoreIntent: (botId, environmentId, messageId) => authRequest({
    url: `/bot/${botId}/environment/${environmentId}/intent/${messageId}/ignore`,
    method: 'POST'
  }),
  ignoreSamples: (botId, environmentId, data) => authRequest({
    url: `/bot/${botId}/environment/${environmentId}/intent/ignore/multiple`,
    method: 'POST',
    data
  }),
  addIntentSample: (botId, environmentId, messageId, data) => authRequest({
    url: `/bot/${botId}/environment/${environmentId}/intent/${messageId}/sample`,
    method: 'POST',
    data
  }),
  addIntent: (botId, environmentId, data) => authRequest({
    url: `/bot/${botId}/environment/${environmentId}/intent`,
    method: 'POST',
    data
  }),
  getIntent: (botId, environmentId, intentId) => authRequest({
    url: `/bot/${botId}/environment/${environmentId}/intent/${intentId}`,
    method: 'GET'
  }),
  updateIntent: (botId, environmentId, intentId, data) => authRequest({
    url: `/bot/${botId}/environment/${environmentId}/intent/${intentId}`,
    method: 'PUT',
    data
  }),
  removeIntent: (botId, environmentId, intentId) => authRequest({
    url: `/bot/${botId}/environment/${environmentId}/intent/${intentId}`,
    method: 'DELETE'
  }),
  getEntities: (botId, environmentId, search = '', page, limit, sort) => authRequest({
    url: `/bot/${botId}/environment/${environmentId}/entity?q=${search}&page=${page}&limit=${limit}&sort=${sort}`,
    method: 'GET'
  }),
  getAllEntities: (botId, environmentId, search = '') => authRequest({
    url: `/bot/${botId}/environment/${environmentId}/entity/all?q=${search}`,
    method: 'GET'
  }),
  addEntity: (botId, environmentId, data) => authRequest({
    url: `/bot/${botId}/environment/${environmentId}/entity`,
    method: 'POST',
    data
  }),
  getEntity: (botId, environmentId, entityId) => authRequest({
    url: `/bot/${botId}/environment/${environmentId}/entity/${entityId}`,
    method: 'GET'
  }),
  updateEntity: (botId, environmentId, entityId, data) => authRequest({
    url: `/bot/${botId}/environment/${environmentId}/entity/${entityId}`,
    method: 'PUT',
    data
  }),
  removeEntity: (botId, environmentId, entityId) => authRequest({
    url: `/bot/${botId}/environment/${environmentId}/entity/${entityId}`,
    method: 'DELETE'
  }),
  getNodes: (search = '', page, limit, sort = 'name') => authRequest({
    url: `/node?q=${search}&page=${page}&limit=${limit}&sort=${sort}`,
    method: 'GET'
  }),
  getFlows: (botId, environmentId, search = '', page, limit, sort = 'name') => authRequest({
    url: `/bot/${botId}/environment/${environmentId}/flow?q=${search}&page=${page}&limit=${limit}&sort=${sort}`,
    method: 'GET'
  }),
  getTransmissionFlows: (botId, environmentId, search = '', page, limit, sort = 'name') => authRequest({
    url: `/bot/${botId}/environment/${environmentId}/flow?q=${search}&page=${page}&limit=${limit}&sort=${sort}&allowTransmission=true`,
    method: 'GET'
  }),
  getBuilds: (botId, environmentId, flowId) => authRequest({
    url: `/bot/${botId}/environment/${environmentId}/flow/${flowId}/build`,
    method: 'GET'
  }),
  resetBuild: (botId, environmentId, flowId, buildId) => authRequest({
    url: `/bot/${botId}/environment/${environmentId}/flow/${flowId}/build/${buildId}`,
    method: 'PUT'
  }),
  getCardsDashboard: (botId, environmentId, startDate, endDate) => authRequest({
    url: `/bot/${botId}/environment/${environmentId}/dashboard/cards?startDate=${startDate}&endDate=${endDate}`,
    method: 'GET'
  }),
  getGraphics: (botId, environmentId, startDate, endDate) => authRequest({
    url: `/bot/${botId}/environment/${environmentId}/dashboard/graphics?startDate=${startDate}&endDate=${endDate}`,
    method: 'GET'
  }),
  addFlow: (botId, environmentId, data) => authRequest({
    url: `/bot/${botId}/environment/${environmentId}/flow`,
    method: 'POST',
    data
  }),
  exportExcel: (botId, environmentId) => authRequest({
    url: `/bot/${botId}/environment/${environmentId}/flow/export/excel`,
    method: 'GET',
    responseType: 'arraybuffer'
  }),
  cloneFlow: (botId, environmentId, data, id) => authRequest({
    url: `/bot/${botId}/environment/${environmentId}/flow/clone/${id}`,
    method: 'POST',
    data
  }),
  getFlow: (botId, environmentId, flowId) => authRequest({
    url: `/bot/${botId}/environment/${environmentId}/flow/${flowId}`,
    method: 'GET'
  }),
  getReport: (environmentId, flowId) => authRequest({
    url: `/flowreport?environment=${environmentId}&flow=${flowId}`,
    method: 'GET'
  }),
  updateFlow: (botId, environmentId, flowId, data) => authRequest({
    url: `/bot/${botId}/environment/${environmentId}/flow/${flowId}`,
    method: 'PUT',
    data
  }),
  updateFlowDiagram: (botId, environmentId, flowId, data) => authRequest({
    url: `/bot/${botId}/environment/${environmentId}/flow/${flowId}/diagram`,
    method: 'PUT',
    data
  }),
  publishFlow: (botId, environmentId, flowId, data) => authRequest({
    url: `/bot/${botId}/environment/${environmentId}/flow/${flowId}/publish`,
    method: 'POST',
    data
  }),
  publishFlowV2: (botId, environmentId, flowId, data) => authRequest({
    url: `/bot/${botId}/environment/${environmentId}/flow/${flowId}/publish/v2`,
    method: 'POST',
    data
  }),
  validateFlow: (botId, environmentId, flowId) => authRequest({
    url: `/bot/${botId}/environment/${environmentId}/flow/${flowId}/validate`,
    method: 'POST'
  }),
  removeFlow: (botId, environmentId, flowId) => authRequest({
    url: `/bot/${botId}/environment/${environmentId}/flow/${flowId}`,
    method: 'DELETE'
  }),
  getChannels: (botId, environmentId, search, page, limit) => authRequest({
    url: `/bot/${botId}/environment/${environmentId}/channel?q=${search}&page=${page}&limit=${limit}`,
    method: 'GET'
  }),
  addChannel: (botId, environmentId, data) => authRequest({
    url: `/bot/${botId}/environment/${environmentId}/channel`,
    method: 'POST',
    data
  }),
  removeChannel: (botId, environmentId, channelId) => authRequest({
    url: `/bot/${botId}/environment/${environmentId}/channel/${channelId}`,
    method: 'DELETE'
  }),
  updateChannel: (botId, environmentId, channelId, data) => authRequest({
    url: `/bot/${botId}/environment/${environmentId}/channel/${channelId}`,
    method: 'PUT',
    data
  }),
  clearQueueChannel: (botId, environmentId, channelId) => authRequest({
    url: `/bot/${botId}/environment/${environmentId}/channel/${channelId}/clearQueue`,
    method: 'GET'
  }),
  getIntegrations: (botId, environmentId, search, page, limit) => authRequest({
    url: `/bot/${botId}/environment/${environmentId}/integration?q=${search}&page=${page}&limit=${limit}`,
    method: 'GET'
  }),
  getIntegrationByType: (botId, environmentId, type) => authRequest({
    url: `/bot/${botId}/environment/${environmentId}/integration/${type}`,
    method: 'GET'
  }),
  addIntegration: (botId, environmentId, data) => authRequest({
    url: `/bot/${botId}/environment/${environmentId}/integration`,
    method: 'POST',
    data
  }),
  removeIntegration: (botId, environmentId, integrationId) => authRequest({
    url: `/bot/${botId}/environment/${environmentId}/integration/${integrationId}`,
    method: 'DELETE'
  }),
  updateVariable: (botId, environmentId, data) => authRequest({
    url: `/bot/${botId}/environment/${environmentId}/variable`,
    method: 'POST',
    data
  }),
  addVariable: (botId, environmentId, data) => authRequest({
    url: `/bot/${botId}/environment/${environmentId}/variable/create`,
    method: 'POST',
    data
  }),
  editVariable: (botId, environmentId, id, data) => authRequest({
    url: `/bot/${botId}/environment/${environmentId}/variable/${id}`,
    method: 'PUT',
    data
  }),
  removeVariable: (botId, environmentId, variableId) => authRequest({
    url: `/bot/${botId}/environment/${environmentId}/variable/${variableId}`,
    method: 'DELETE'
  }),
  subscribeTopic: (data) => runtimeRequest({
    url: `/topic/subscribe`,
    method: 'POST',
    data
  }),
  getConversations: (botId, environmentId, search, filter, page, limit) => authRequest({
    url: `/bot/${botId}/environment/${environmentId}/transcriptor?q=${search}${filter}&page=${page}&limit=${limit}&sort=-updatedAt`,
    method: 'GET'
  }),
  getListConversations: (botId, environmentId, params) => authRequest({
    url: `/bot/${botId}/environment/${environmentId}/transcriptor`,
    params,
    method: 'GET'
  }),
  getConversationsRequests: (botId, environmentId, data, params) => authRequest({
    url: `/bot/${botId}/environment/${environmentId}/transcriptor/requests${paramsToString(params)}`,
    method: 'POST',
    data
  }),
  getRequestTrace: (botId, environmentId, conversationId, logStream, createdAt) => authRequest({
    url: `/bot/${botId}/environment/${environmentId}/transcriptor/requests/${conversationId}/trace?logStream=${logStream}&logTime=${createdAt}`,
    method: 'GET'
  }),
  getRequestLogs: (botId, environmentId, conversationId, logId, logStream, createdAt) => authRequest({
    url: `/bot/${botId}/environment/${environmentId}/transcriptor/requests/${conversationId}/logs/${logId}?logStream=${logStream}&logTime=${createdAt}`,
    method: 'GET'
  }),
  getConversationsByChannel: (botId, environmentId) => authRequest({
    url: `/bot/${botId}/environment/${environmentId}/transcriptor/channel`,
    method: 'GET'
  }),
  getUserFields: (botId, environmentId) => authRequest({
    url: `/bot/${botId}/environment/${environmentId}/transcriptor/fields`,
    method: 'GET'
  }),
  getAvailableUsers: (botId, environmentId, data) => authRequest({
    url: `/bot/${botId}/environment/${environmentId}/transcriptor/filter`,
    method: 'POST',
    data
  }),
  updateUserVariables: (botId, environmentId, data) => authRequest({
    url: `/bot/${botId}/environment/${environmentId}/transcriptor/user`,
    method: 'PUT',
    data
  }),
  getConversationsByChannelBroadcast: (botId, environmentId) => authRequest({
    url: `/bot/${botId}/environment/${environmentId}/transcriptor/channelBroadcast`,
    method: 'GET'
  }),
  getMessages: (botId, environmentId, conversationId, search = '', page, limit) => authRequest({
    url: `/bot/${botId}/environment/${environmentId}/transcriptor/${conversationId}?${search}&page=${page}&limit=${limit}&sort=-createdAt`,
    method: 'GET'
  }),
  getLogs: (botId, environmentId, conversationId, logId) => authRequest({
    url: `/bot/${botId}/environment/${environmentId}/transcriptor/${conversationId}/logs/${logId}`,
    method: 'GET'
  }),
  sendMessage: (botId, environmentId, data) => authRequest({
    url: `/bot/${botId}/environment/${environmentId}/transcriptor/message`,
    method: 'POST',
    data
  }),
  handOver: (botId, environmentId, data) => authRequest({
    url: `/bot/${botId}/environment/${environmentId}/transcriptor/handover`,
    method: 'POST',
    data
  }),
  getBroadcasts: (botId, environmentId, search = '', page, limit, sort) => authRequest({
    url: `/bot/${botId}/environment/${environmentId}/broadcast?q=${search}&page=${page}&limit=${limit}&sort=${sort}`,
    method: 'GET'
  }),
  getRemovedBroadcasts: (botId, environmentId, search = '', page, limit, sort) => authRequest({
    url: `/bot/${botId}/environment/${environmentId}/broadcast?q=${search}&page=${page}&limit=${limit}&sort=${sort}&active=false`,
    method: 'GET'
  }),
  addBroadcast: (botId, environmentId, data) => authRequest({
    url: `/bot/${botId}/environment/${environmentId}/broadcast`,
    method: 'POST',
    data
  }),
  getBroadcast: (botId, environmentId, broadcastId) => authRequest({
    url: `/bot/${botId}/environment/${environmentId}/broadcast/${broadcastId}`,
    method: 'GET'
  }),
  updateBroadcast: (botId, environmentId, broadcastId, data) => authRequest({
    url: `/bot/${botId}/environment/${environmentId}/broadcast/${broadcastId}`,
    method: 'PUT',
    data
  }),
  removeUserBroadcast: (botId, environmentId, broadcastId, userId) => authRequest({
    url: `/bot/${botId}/environment/${environmentId}/broadcast/${broadcastId}/user/${userId}`,
    method: 'DELETE'
  }),
  addUserBroadcast: (botId, environmentId, broadcastId, data) => authRequest({
    url: `/bot/${botId}/environment/${environmentId}/broadcast/${broadcastId}/manual`,
    method: 'POST',
    data
  }),
  addUserConversationBroadcast: (botId, environmentId, broadcastId, data) => authRequest({
    url: `/bot/${botId}/environment/${environmentId}/broadcast/${broadcastId}/conversation`,
    method: 'POST',
    data
  }),
  uploadBroadcast: (botId, environmentId, broadcastId, data) => authRequest({
    url: `/bot/${botId}/environment/${environmentId}/broadcast/${broadcastId}/import`,
    method: 'POST',
    data
  }),
  sendBroadcast: (botId, environmentId, broadcastId) => authRequest({
    url: `/bot/${botId}/environment/${environmentId}/broadcast/${broadcastId}/send`,
    method: 'POST'
  }),
  removeBroadcast: (botId, environmentId, broadcastId) => authRequest({
    url: `/bot/${botId}/environment/${environmentId}/broadcast/${broadcastId}`,
    method: 'DELETE'
  }),
  getBroadcastStats: (botId, environmentId, broadcastId, page, limit, sort) => authRequest({
    url: `/bot/${botId}/environment/${environmentId}/broadcast/${broadcastId}/stats?page=${page}&limit=${limit}&sort=${sort}`,
    method: 'GET'
  }),
  exportBroadcastStats: (botId, environmentId, broadcastId) => authRequest({
    url: `/bot/${botId}/environment/${environmentId}/broadcast/${broadcastId}/stats/export`,
    method: 'GET'
  }),


  getEvents: (botId, environmentId, search = '', startDate, endDate, page, limit, sort) => authRequest({
    url: `/bot/${botId}/environment/${environmentId}/event?q=${search}&startDate=${startDate}&endDate=${endDate}&page=${page}&limit=${limit}&sort=${sort}`,
    method: 'GET'
  }),
  getEvent: (botId, environmentId, eventId) => authRequest({
    url: `/bot/${botId}/environment/${environmentId}/event/${eventId}`,
    method: 'GET'
  }),
  downloadEvents: (botId, environmentId, startDate, endDate) => authRequest({
    url: `/bot/${botId}/environment/${environmentId}/event/export/excel?startDate=${startDate}&endDate=${endDate}`,
    method: 'GET',
    responseType: 'arraybuffer'
  }),

  getFlowsToJumpHere: (botId, environmentId, flowId) => authRequest({
    url: `/bot/${botId}/environment/${environmentId}/flow/${flowId}/jumpFlows`,
    method: 'GET'
  })
}
