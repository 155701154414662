import { createReducer } from '../../reducers'
import { actionTypes } from './action'

export const initialState = () => ({
  loading: false,
  unhandledLoading: false,
  dialogLoading: false,
  contextLoading: false,
  intents: [],
  autoCompleteIntents: [],
  unhandledIntents: [],
  contextMessages: [],
  unhandledCount: 0,
  intent: null,
  openAddSample: false,
  openAddIntent: false,
  openIgnoreSamples: false,
  openEditIntent: false
})

export const reducer = {
  [actionTypes.SET_LOADING](state, { value }) {
    return { ...state, loading: value }
  },
  [actionTypes.SET_UNHANDLED_LOADING](state, { value }) {
    return { ...state, unhandledLoading: value }
  },
  [actionTypes.SET_CONTEXT_LOADING](state, { value }) {
    return { ...state, contextLoading: value }
  },
  [actionTypes.SET_DIALOG_LOADING](state, { value }) {
    return { ...state, dialogLoading: value }
  },
  [actionTypes.SET_INTENTS](state, { data }) {
    return { ...state, intents: data.rows, count: data.count }
  },
  [actionTypes.SET_AUTOCOMPLETE_INTENTS](state, { data }) {
    return { ...state, autoCompleteIntents: data.rows }
  },
  [actionTypes.SET_CONTEXT_MESSAGES](state, { data }) {
    return { ...state, contextMessages: data.rows }
  },
  [actionTypes.SET_UNHANDLED_INTENTS](state, { data }) {
    return { ...state, unhandledIntents: data.rows, unhandledCount: data.count }
  },
  [actionTypes.SET_INTENT](state, { data }) {
    return { ...state, intent: data }
  },
  [actionTypes.OPEN_IGNORE_SAMPLES](state, { value }) {
    return { ...state, openIgnoreSamples: value }
  },
  [actionTypes.OPEN_ADD_SAMPLE](state, { value }) {
    return { ...state, openAddSample: value }
  },
  [actionTypes.OPEN_ADD_INTENT](state, { value }) {
    return { ...state, openAddIntent: value }
  },
  [actionTypes.OPEN_EDIT_INTENT](state, { value }) {
    return { ...state, openEditIntent: value, intent: null }
  },
  [actionTypes.INITIAL_STATE]() {
    return initialState()
  }
}

export default createReducer(initialState(), reducer)
