import { createReducer } from '../../reducers'
import { actionTypes } from './action'

export const initialState = () => ({
  loading: false,
  saving: false,
  publishing: false,
  dialogLoading: false,
  loadingSend: false,
  loadingBroadcast: false,
  loadingAvailable: false,
  loadingBroadcastStats: false,
  broadcasts: [],
  count: 0,
  broadcastStats: [],
  countStats: 0,
  broadcast: null,
  availableUsers: null,
  openUpload: false,
  openAddBroadcast: false,
  openEditBroadcast: false,
  validation: {},
  report: {},
  conversationsByChannel: [],
  userFields: [],

  loadingRemovedBroadcasts: false,
  removedBroadcasts: [],
  loadingDownload: false,
})

export const reducer = {
  [actionTypes.SET_CONVERSATIONS_BY_CHANNEL](state, { data }) {
    return { ...state, conversationsByChannel: data.rows }
  },
  [actionTypes.SET_LOADING](state, { value }) {
    return { ...state, loading: value }
  },
  [actionTypes.SET_LOADING_SEND](state, { value }) {
    return { ...state, loadingSend: value }
  },
  [actionTypes.SET_LOADING_BROADCAST](state, { value }) {
    return { ...state, loadingBroadcast: value }
  },
  [actionTypes.SET_LOADING_BROADCAST_STATS](state, { value }) {
    return { ...state, loadingBroadcastStats: value }
  },
  [actionTypes.SET_LOADING_AVAILABLE](state, { value }) {
    return { ...state, loadingAvailable: value }
  },
  [actionTypes.SET_PUBLISHING](state, { value }) {
    return { ...state, publishing: value }
  },
  [actionTypes.SET_SAVING](state, { value }) {
    return { ...state, saving: value }
  },
  [actionTypes.SET_DIALOG_LOADING](state, { value }) {
    return { ...state, dialogLoading: value }
  },
  [actionTypes.SET_LOADING_DOWNLOAD](state, { value }) {
    return { ...state, loadingDownload: value }
  },
  [actionTypes.SET_BROADCASTS](state, { data }) {
    return { ...state, broadcasts: data.rows, count: data.count }
  },
  [actionTypes.SET_BROADCAST_STATS](state, { data }) {
    return { ...state, broadcastStats: data.rows, countStats: data.count }
  },
  [actionTypes.SET_BROADCAST](state, { data }) {
    return { ...state, broadcast: data }
  },
  [actionTypes.SET_AVAILABLE_USERS](state, { data }) {
    return { ...state, availableUsers: data }
  },
  [actionTypes.SET_VALIDATION](state, { data }) {
    return { ...state, validation: data }
  },
  [actionTypes.SET_REPORT](state, { data }) {
    return { ...state, report: data }
  },
  [actionTypes.OPEN_ADD_BROADCAST](state, { value }) {
    return { ...state, openAddBroadcast: value }
  },
  [actionTypes.OPEN_UPLOAD](state, { value }) {
    return { ...state, openUpload: value }
  },
  [actionTypes.OPEN_EDIT_BROADCAST](state, { value }) {
    return { ...state, openEditBroadcast: value, broadcast: null }
  },
  [actionTypes.OPEN_ADD_USER_CONVERSATIONS](state, { value }) {
    return { ...state, openAddUserConversations: value }
  },
  [actionTypes.OPEN_ADD_USER_IMPORT](state, { value }) {
    return { ...state, openAddUserImport: value }
  },
  [actionTypes.OPEN_ADD_USER_MANUAL](state, { value }) {
    return { ...state, openAddUserManual: value }
  },
  [actionTypes.SET_USER_FIELDS](state, { data }) {
    return { ...state, userFields: data.rows }
  },
  [actionTypes.SET_REMOVED_LOADING](state, { value }) {
    return { ...state, loadingRemovedBroadcasts: value }
  },
  [actionTypes.SET_REMOVED_BROADCASTS](state, { data }) {
    return { ...state, removedBroadcasts: data.rows, removedBroadcastsCount: data.count }
  },
  [actionTypes.INITIAL_STATE]() {
    return initialState()
  }
}

export default createReducer(initialState(), reducer)
