import { createReducer } from '../../reducers'
import { actionTypes } from './action'

export const initialState = () => ({
  loading: false,
  invite: null,
  refreshing: false,
  forgotPassword: false,
  twoFactor: false,
  twoFactorLoading: false,
  forgotLoading: false,
  forgot: null
})

export const reducer = {
  [actionTypes.SET_LOADING](state, { value }) {
    return { ...state, loading: value }
  },
  [actionTypes.SET_FORGOT_LOADING](state, { value }) {
    return { ...state, forgotLoading: value }
  },
  [actionTypes.SET_FORGOT_PASSWORD](state, { value }) {
    return { ...state, forgotPassword: value }
  },
  [actionTypes.SET_TWO_FACTOR](state, { value }) {
    return { ...state, twoFactor: value }
  },
  [actionTypes.SET_TWO_FACTOR_LOADING](state, { value }) {
    return { ...state, twoFactorLoading: value }
  },
  [actionTypes.SET_FORGOT](state, { data }) {
    return { ...state, forgot: data }
  },
  [actionTypes.SET_INVITE](state, { data }) {
    return { ...state, invite: data }
  },
  [actionTypes.SET_REFRESH](state, { value }) {
    return { ...state, refreshing: value }
  },
  [actionTypes.INITIAL_STATE]() {
    return initialState()
  }
}

export default createReducer(initialState(), reducer)
