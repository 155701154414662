import API from 'api'
import toastActions from 'states/toast/action'

export const actionTypes = {
  OPEN_ADD_INTEGRATION: 'INTEGRATION/OPEN_ADD_INTEGRATION',
  SET_DIALOG_LOADING: 'INTEGRATION/SET_DIALOG_LOADING',
  SET_LOADING: 'INTEGRATION/SET_LOADING',
  SET_INTEGRATIONS: 'INTEGRATION/SET_INTEGRATIONS',
  INITIAL_STATE: 'INTEGRATION/INITIAL_STATE'
}

export const actions = {
  addIntegration: body => async (dispatch, getState) => {
    const { environment, bot } = getState()
    const botId = bot.bot._id
    const environmentId = environment.environment._id
    dispatch(actions.setDialogLoading(true))
    try {
      await API.addIntegration(botId, environmentId, body)
      dispatch(actions.openAddIntegration(false))
      dispatch(actions.getIntegrations())
      dispatch(toastActions.showToast('Integration added successfully.'))
    } catch (error) {
    } finally {
      dispatch(actions.setDialogLoading(false))
    }
  },
  getIntegrations: () => async (dispatch, getState) => {
    const { environment, bot } = getState()
    const botId = bot.bot._id
    const environmentId = environment.environment._id
    dispatch(actions.setLoading(true))
    try {
      const { data } = await API.getIntegrations(botId, environmentId, '', 1, 100)
      dispatch(actions.setIntegrations(data))
    } catch (error) {
    } finally {
      dispatch(actions.setLoading(false))
    }
  },
  removeIntegration: id => async (dispatch, getState) => {
    const { environment, bot } = getState()
    const botId = bot.bot._id
    const environmentId = environment.environment._id
    dispatch(actions.setDialogLoading(true))
    try {
      await API.removeIntegration(botId, environmentId, id)
      dispatch(actions.openAddIntegration(false))
      dispatch(actions.getIntegrations())
      dispatch(toastActions.showToast('Integration removed successfully.'))
    } catch (error) {
      const message = error.response ?.data ?.message || 'Unexpected error.'
      dispatch(toastActions.showToast(message))
    } finally {
      dispatch(actions.setDialogLoading(false))
    }
  },
  setLoading: value => ({ type: actionTypes.SET_LOADING, value }),
  setIntegrations: data => ({ type: actionTypes.SET_INTEGRATIONS, data }),
  openAddIntegration: (value, integration) => ({ type: actionTypes.OPEN_ADD_INTEGRATION, value, selectedIntegration: integration }),
  setDialogLoading: value => ({ type: actionTypes.SET_DIALOG_LOADING, value }),
  initialState: () => (dispatch) => {
    dispatch({ type: actionTypes.INITIAL_STATE })
  }
}

export default actions
