import API from 'api'

export const actionTypes = {
  SET_LOADING_CONVERSATIONS: 'CONVERSATIONS/SET_LOADING_CONVERSATIONS',
  SET_LOADING_MESSAGES: 'CONVERSATIONS/SET_LOADING_MESSAGES',
  SET_LOADING_OLD_MESSAGES: 'CONVERSATIONS/SET_LOADING_OLD_MESSAGES',
  SET_CONVERSATION: 'CONVERSATIONS/SET_CONVERSATION',
  SET_CONVERSATIONS: 'CONVERSATIONS/SET_CONVERSATIONS',
  SET_MESSAGES: 'CONVERSATIONS/SET_MESSAGES',
  SET_OPEN_MODAL_CONVERSATION: 'CONVERSATIONS/SET_OPEN_MODAL_CONVERSATION',
  INITIAL_STATE: 'CONVERSATIONS/INITIAL_STATE'
}

export const actions = {
  getConversations: ({
    search = '', page = 0, limit = 100, sort = '-updatedAt', startDate, endDate, channel,
    property = '', comparator = '', value = ''
  }) => async (dispatch, getState) => {
    const { environment, bot } = getState()
    const botId = bot.bot._id
    const environmentId = environment.environment._id
    dispatch(actions.setLoadingConversations(true))
    try {
      const { data } = await API.getListConversations(botId, environmentId, {
        q: search, page: page + 1, limit, sort, startDate, endDate, channel,
        property, comparator, value
      })
      dispatch(actions.setConversations(data))
    } catch (error) { } finally {
      dispatch(actions.setLoadingConversations(false))
    }
  },
  getMessages: (limit = 30) => async (dispatch, getState) => {
    const { environment, bot, conversations } = getState()
    const botId = bot.bot._id
    const environmentId = environment.environment._id
    const conversationId = conversations.conversation.identifier

    dispatch(actions.setLoadingMessages(true))
    try {
      const { data } = await API.getMessages(botId, environmentId, conversationId, '', 1, limit)
      data.rows.reverse()
      dispatch(actions.setMessages({ ...data, hasOlderMessages: data.count > data.rows.length }))
    } catch (error) { } finally {
      dispatch(actions.setLoadingMessages(false))
    }
  },
  getOldMessages: (limit = 30) => async (dispatch, getState) => {
    const { environment, bot, conversations } = getState()
    const botId = bot.bot._id
    const environmentId = environment.environment._id
    const conversationId = conversations.conversation.identifier

    dispatch(actions.setLoadingOldMessages(true))
    try {
      const lastMessage = [...conversations.messages][0]
      const { data } = await API.getMessages(botId, environmentId, conversationId, `before=${lastMessage.createdAt}`, 1, limit)
      data.rows.reverse()
      const messages = [...data.rows, ...conversations.messages]
      dispatch(actions.setMessages({ rows: messages, hasOlderMessages: data.rows.length > 0 }))
    } catch (error) { } finally {
      dispatch(actions.setLoadingOldMessages(false))
    }
  },
  openModalConversation: value => async (dispatch) => {
    dispatch({ type: actionTypes.SET_OPEN_MODAL_CONVERSATION, value: !!value })
    dispatch(actions.setConversation(value))
  },
  updateMessage: data => async (dispatch, getState) => {
    const { conversations } = getState()
    const { messages } = conversations
    for (const index in messages) {
      if (messages[index].id === data.id) {
        messages[index] = data
        break
      }
    }
    dispatch(actions.setMessages({ rows: [...messages] }))
  },
  setLoadingConversations: value => ({ type: actionTypes.SET_LOADING_CONVERSATIONS, value }),
  setLoadingMessages: value => ({ type: actionTypes.SET_LOADING_MESSAGES, value }),
  setLoadingOldMessages: value => ({ type: actionTypes.SET_LOADING_OLD_MESSAGES, value }),
  setConversations: data => ({ type: actionTypes.SET_CONVERSATIONS, data }),
  setConversation: data => ({ type: actionTypes.SET_CONVERSATION, data }),
  setPageOldMessages: value => ({ type: actionTypes.SET_PAGE_OLD_MESSAGES, value }),
  setMessages: data => ({ type: actionTypes.SET_MESSAGES, data }),
  initialState: () => (dispatch) => {
    dispatch({ type: actionTypes.INITIAL_STATE })
  }
}

export default actions
