import queryString from 'query-string'

const appendLinkHeaderHtml = href => {
  var link = document.querySelector("link[rel*='icon']") || document.createElement('link')
  link.href = href
  link.type = 'image/x-icon'
  link.rel = 'shortcut icon'
  document.getElementsByTagName('head')[0].appendChild(link)
}

export const setPageIcon = () => {
  if (window.location.hostname === 'admin.ezchat.com.br') {
    appendLinkHeaderHtml(require('assets/img/ezChat/ezchatbotFavicon.svg'))
  } else if (window.location.hostname === 'admin.talkershop.com.br') {
    appendLinkHeaderHtml('https://talkershop.com.br/img/favicon.png')
  } else if (window.location.hostname === 'admin.dendron.com.br') {
    appendLinkHeaderHtml('https://bots-flow-assets.s3.sa-east-1.amazonaws.com/dendron/dendron3.png')
  } else if (window.location.hostname === 'painel.getbots.com.br' || window.location.hostname === 'studio.getbots.com.br') {
    appendLinkHeaderHtml(require('assets/img/getbots.png'))
  } else if (window.location.hostname === 'admin.nexflow.app' || window.location.hostname === 'studio.hyperflow.global') {
    appendLinkHeaderHtml(require('assets/img/flowFavicon.png'))

  }
}

export const getWhitelabel = () => {
  try {
    let whitelabel = 'nexFlow'

    let { wl } = queryString.parse(window.location.search)

    if (wl) {
      whitelabel = wl
    } else if (window.location.hostname === 'admin.ezchat.com.br') {
      whitelabel = 'ezchatbot'
      if (localStorage.getItem("whitelabel") === 'comolatti' || window.location.href.includes('comolatti')) {
        whitelabel = 'comolatti'
      }
    } else if (window.location.hostname === 'admin.talkershop.com.br') {
      whitelabel = 'talkershop'
    } else if (window.location.hostname === 'painel.getbots.com.br' || window.location.hostname === 'studio.getbots.com.br') {
      whitelabel = 'getbots'
    } else if (window.location.hostname === 'admin.dendron.com.br') {
      whitelabel = 'dendron'
    } else if (window.location.hostname === 'admin.nexflow.app' || window.location.hostname === 'studio.hyperflow.global') {
      whitelabel = 'nexFlow'
    } else if (window.location.hostname === 'admin.schatbot.com.br') {
      whitelabel = 'schatbot'
    } else if (localStorage.getItem('whitelabel')) {
      whitelabel = localStorage.getItem('whitelabel')
      if (whitelabel === 'ezchat') {
        whitelabel = 'ezchatbot'
      }
    }

    localStorage.setItem('whitelabel', whitelabel || 'nexFlow')

    return whitelabel
  } catch (error) {
    return 'ezchatbot'
  }
}
