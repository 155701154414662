import { combineReducers } from 'redux'
import auth from 'states/auth/reducer'
import toast from 'states/toast/reducer'
import bot from 'states/bot/reducer'
import environment from 'states/environment/reducer'
import user from 'states/user/reducer'
import nlp from 'states/nlp/reducer'
import variable from 'states/variable/reducer'
import flow from 'states/flow/reducer'
import dashboard from 'states/dashboard/reducer'
import intent from 'states/intent/reducer'
import event from 'states/event/reducer'
import entity from 'states/entity/reducer'
import channel from 'states/channel/reducer'
import broadcast from 'states/broadcast/reducer'
import conversations from 'states/conversations/reducer'
import transcriptor from 'states/transcriptor/reducer'
import integration from 'states/integration/reducer'
import { routerReducer } from 'react-router-redux'


export function createReducer(initialState, actions) {
  return (state = initialState, action) => {
    if (action.type === '@@RESET') {
      return initialState
    }

    const reducer = actions[action.type]
    return reducer ? reducer(state, action) : state
  }
}

export default combineReducers({
  auth,
  bot,
  environment,
  user,
  flow,
  dashboard,
  event,
  nlp,
  variable,
  intent,
  entity,
  toast,
  channel,
  broadcast,
  conversations,
  transcriptor,
  integration,
  routing: routerReducer
})
