import { createReducer } from '../../reducers'
import { actionTypes } from './action'

export const initialState = () => ({
  messages: [],
  hasOlderMessages: false,
  openLogs: false,
  openHandOver: false,
  logs: [],
  loadingConversations: false,
  loadingMessages: false,
  loadingOldMessages: false,
  conversations: [],
  conversationsByChannel: [],
  conversation: null,
  loadingHandOver: false,
  loadingUserEdit: false
})

export const reducer = {
  [actionTypes.SET_LOADING_CONVERSATIONS](state, { value }) {
    return { ...state, loadingConversations: value }
  },
  [actionTypes.SET_LOADING_MESSAGES](state, { value }) {
    return { ...state, loadingMessages: value }
  },
  [actionTypes.SET_LOADING_OLD_MESSAGES](state, { value }) {
    return { ...state, loadingOldMessages: value }
  },
  [actionTypes.SET_LOADING_HANDOVER](state, { value }) {
    return { ...state, loadingHandOver: value }
  },
  [actionTypes.SET_LOADING_EDIT_USER](state, { value }) {
    return { ...state, loadingUserEdit: value }
  },
  [actionTypes.OPEN_LOGS](state, { value }) {
    return { ...state, openLogs: value }
  },
  [actionTypes.OPEN_HANDOVER](state, { value }) {
    return { ...state, openHandOver: value }
  },
  [actionTypes.SET_HANDOVER](state, { data }) {
    const conversations = state.conversations
    for (const conversation of conversations) {
      if (conversation.identifier === data.identifier) {
        conversation.user = data.user
      }
    }
    return { ...state, conversation: data, conversations }
  },
  [actionTypes.SET_CONVERSATION](state, { data }) {
    return { ...state, conversation: data, messages: [] }
  },
  [actionTypes.SET_CONVERSATIONS](state, { data }) {
    return { ...state, conversations: data.rows }
  },
  [actionTypes.SET_CONVERSATIONS_BY_CHANNEL](state, { data }) {
    return { ...state, conversationsByChannel: data.rows }
  },
  [actionTypes.SET_MESSAGES](state, { data }) {
    return { ...state, messages: data.rows, hasOlderMessages: data.hasOlderMessages }
  },
  [actionTypes.SET_LOGS](state, { data }) {
    return { ...state, logs: data }
  },
  [actionTypes.INITIAL_STATE]() {
    return initialState()
  }
}

export default createReducer(initialState(), reducer)
