import { applyMiddleware, createStore, compose } from 'redux'
import thunkMiddleware from 'redux-thunk'
import reducers from './reducers'
import createBrowserHistory from 'history/createBrowserHistory'
import { routerMiddleware } from 'react-router-redux'

const composeEnhancers = compose
export const history = createBrowserHistory()

const middleware = [
  thunkMiddleware,
  routerMiddleware(history)
]

const store = composeEnhancers(applyMiddleware(...middleware))(createStore)
export default store(reducers)
