import { createReducer } from '../../reducers'
import { actionTypes } from './action'

export const initialState = () => ({
  loading: false,
  saving: false,
  publishing: false,
  dialogLoading: false,
  flows: [],
  transmissionFlows: [],
  builds: [],
  count: 0,
  countTransmissionFlows: 0,
  flow: null,
  openAddFlow: false,
  openImportFlow: false,
  cloneFlow: null,
  openEditFlow: false,
  openBuilds: false,
  openPublish: false,
  openPublishMany: false,
  openPublishManyLoading: null,
  buildInfo: false,
  validationErrors: [],
  validation: {},
  report: {},
  loadingDownload: false,
  showFormPublishMany: false,
  loadingFlowsValidation: false
})

export const reducer = {
  [actionTypes.SET_LOADING](state, { value }) {
    return { ...state, loading: value }
  },
  [actionTypes.SET_LOADING_DOWNLOAD](state, { value }) {
    return { ...state, loadingDownload: value }
  },
  [actionTypes.SET_PUBLISHING](state, { value }) {
    return { ...state, publishing: value }
  },
  [actionTypes.SET_SAVING](state, { value }) {
    return { ...state, saving: value }
  },
  [actionTypes.SET_DIALOG_LOADING](state, { value }) {
    return { ...state, dialogLoading: value }
  },
  [actionTypes.SET_FLOWS](state, { data }) {
    return { ...state, flows: data.rows, count: data.count }
  },
  [actionTypes.SET_TRANSMISSION_FLOWS](state, { data }) {
    return { ...state, transmissionFlows: data.rows, countTransmissionFlows: data.count }
  },
  [actionTypes.SET_FLOW](state, { data }) {
    return { ...state, flow: data }
  },
  [actionTypes.SET_VALIDATION](state, { data }) {
    return { ...state, validation: data }
  },
  [actionTypes.SET_BUILD_INFO](state, { data }) {
    return { ...state, buildInfo: data }
  },
  [actionTypes.SET_VALIDATION_ERRORS](state, { data }) {
    return { ...state, validationErrors: data }
  },
  [actionTypes.SET_REPORT](state, { data }) {
    return { ...state, report: data }
  },
  [actionTypes.SET_BUILDS](state, { data }) {
    return { ...state, builds: data.rows }
  },
  [actionTypes.OPEN_ADD_FLOW](state, { value }) {
    return { ...state, openAddFlow: value }
  },
  [actionTypes.OPEN_IMPORT_FLOW](state, { value }) {
    return { ...state, openImportFlow: value }
  },
  [actionTypes.OPEN_BUILDS](state, { value }) {
    return { ...state, openBuilds: value }
  },
  [actionTypes.OPEN_PUBLISH](state, { value }) {
    return { ...state, openPublish: value }
  },
  [actionTypes.OPEN_PUBLISH_MANY](state, { value }) {
    return { ...state, openPublishMany: value }
  },
  [actionTypes.OPEN_PUBLISH_MANY_LOADING](state, { data }) {
    return { ...state, openPublishManyLoading: data }
  },
  [actionTypes.SET_CLONE_FLOW](state, { value }) {
    return { ...state, cloneFlow: value }
  },
  [actionTypes.OPEN_EDIT_FLOW](state, { value }) {
    return { ...state, openEditFlow: value }
  },
  [actionTypes.SET_LOADING_FLOWS_VALIDATION](state, { value }) {
    return { ...state, loadingFlowsValidation: value }
  },
  [actionTypes.SET_SHOW_FORM_PUBLISH_MANY](state, { value }) {
    return { ...state, showFormPublishMany: value }
  },
  [actionTypes.INITIAL_STATE]() {
    return initialState()
  }
}

export default createReducer(initialState(), reducer)
