export default {
  EDIT: 'EDITAR',
  'Search here': 'Pesquise aqui',
  Home: 'Início',
  Flows: 'Fluxos',
  Channels: 'Canais',
  Broadcast: 'Transmissão',
  Events: 'Eventos',
  Integration: 'Integração',
  Integrations: 'Integrações',
  NLP: 'NLP',
  Variables: 'Variáveis',
  Transcriptor: 'Tempo real',
  Conversations: 'Conversas',
  Settings: 'Configurações',
  'Loading sessions and messages': 'Carregando sessões e mensagens',
  'Received Msgs': 'Recebidas',
  'Sended Msgs': 'Enviadas',
  Sessions: 'Sessões',
  Users: 'Usuários',
  'Users and messages': 'Usuários e mensagens',
  'Updated on': 'Atualizado às',
  'Sessions and messages from': 'Sessões e mensagens de',
  until: 'até',
  Until: 'Até',
  'Start date': 'Data início',
  'End date': 'Data final',
  'Change Tenant': 'Trocar conta',
  'Selected Tenant': 'Conta selecionada',
  Logout: 'Sair',
  'Selected account': 'Conta selecionada',
  'Create bot': 'Criar bot',
  'Enter your bot name and description': 'Insira o nome e descrição do seu bot',
  'No bots found': 'Nenhum bot encontrado',
  Name: 'Nome',
  Description: 'Descrição',
  Create: 'Criar',
  Close: 'Fechar',
  'Remove bot': 'Remover bot',
  'Update dashboard': 'Atualizar dashboard',
  'Flow list': 'Lista de fluxos',
  'Create and edit your flows': 'Crie e edite os seus fluxos',
  'Created at': 'Criado em',
  Interrupt: 'Pausar',
  'Updated at': 'Atualizado em',
  Options: 'Opções',
  'Import flow': 'Importar fluxo',
  'Create flow': 'Criar fluxo',
  Edit: 'Editar',
  Clone: 'Clonar',
  Remove: 'Remover',
  'No flows found': 'Nenhum fluxo encontrado',
  'Change environment': 'Mudar ambiente',
  'Selected environment': 'Ambiente selecionado',
  'Add environment': 'Add ambiente',
  'Environment settings': 'Dados do ambiente',
  'Edit your environment settings': 'Edite o nome do seu ambiente',
  'Channels list': 'Lista de canais',
  'Manage your channels': 'Gerencie e conecte seus fluxos a novos canais',
  'Loading channels': 'Carregando canais',
  CONNECTED: 'CONECTADO',
  CONNECT: 'CONECTAR',
  PUBLISHED: 'PUBLICADO',
  DRAFT: 'RASCUNHO',
  'You can edit all of the web chat options and configurations in the JSON below': 'Você pode editar todas as opções e configurações do web chat no JSON abaixo',
  'JSON config': 'Configuração em JSON',
  'Add web chat': 'Adicionar novo web chat',
  'Please fill informations below to embed web chat to your site.': 'Por favor, preencha as informações abaixo para adicionar o chat ao seu site.',
  'Please fill informations below to embed web chat to your site': 'Por favor, preencha as informações abaixo para adicionar o chat ao seu site',
  'Show popup the first time': 'Mostrar popup na primeira vez',
  'Send url params on start flow': 'Enviar parâmetros de URL no inicio do chat',
  'Allow upload file': 'Permitir upload de arquivos',
  'Update': 'Atualizar',
  'Create new environment': 'Criar novo ambiente',
  'Create a new environment in your application': 'Crie um novo ambiente no seu projeto',
  'channel info': 'informações do canal',
  'Disconnect Channel': 'Desconectar canal',
  'Disconnect': 'Desconectar',
  'Do you really want to disconnect this channel?': 'Você realmente deseja desconectar esse canal?',
  'Title': 'Título',
  'Subtitle': 'Subtítulo',
  'Start Button Description': 'Descrição do Botão Iniciar',
  'Start Button Title': 'Título do Botão Iniciar',
  'Horizontal Positioning': 'Posição horizontal',
  'Align Left': 'Alinhado a Esquerda',
  'Align Right': 'Alinhado a Direita',
  'Add new website': 'Adicionar novo webchat',
  'Add new': 'Adicionar novo',
  'Connect': 'Conectar',
  'Button web icon image (optional)': 'Ícone do site (opcional)',
  'Web icon full size': 'Ícone do site tamanho completo',
  'Imports': 'Importar',
  'General': 'Geral',
  'Copy and paste the code below, on end of BODY in your site': 'Copie e cole o código abaixo, no fim da tag BODY no seu site:',
  'Layout': 'Layout',
  'Float Button': 'Botão Flutuante',
  'Badge - Text Color': 'Badge - Cor do texto',
  'Badge - Background Color': 'Badge - Cor de fundo',
  'Saving': 'Salvando',
  'Disconnecting': 'Desconectando',
  'Select one flow': 'Selecione um flow',
  'Show powered by': 'Mostrar Powered By',
  'Alignment powered by': 'Alinhamento do Powered By',
  'Powered by text': 'Texto do Powered By',
  'Powered by link': 'Link do Powered By',
  'Contact': 'Contato',
  'Add button': 'Adicionar botão',
  'Edit flow': "Editar fluxo",
  'Edit flow name and description': 'Editar nome do fluxo e descrição',
  'Add WhatsApp Twilio Channel': 'Adicionar WhatsApp Twilio',
  'WhatsApp Gupshup channels': 'Números vinculados a Gupshup',
  'Update existing channels or add new WhatsApp Gupshup channel': 'Atualize os números existentes ou adicione um novo número da Gupshup',
  'Update WhatsApp Twilio Channel': 'Atualize o número vinculado a Twilio',
  'Update WhatsApp Gupshup Channel': 'Atualize o número vinculado a Gupshup',
  'Please, fill the WhatsApp credentials below': 'Por favor, insira as credenciais para vincular o WhatsApp abaixo',
  'API Key': 'Chave de API',
  'Add Attendance Integration': 'Integração de atendimento humano',
  'Channel removed successfully': 'Canal removido com sucesso',
  'Insert your Attendance API Key': 'Insira a sua chave de API de atendimento humano',
  'Continue automatically': 'Continuar automaticamente',
  'Validate input': 'Validar resposta',
  'Wait user input': 'Aguardar resposta do usuário',
  'Out of context message': 'Mensagem fora de contexto',
  'Validate input': 'Validar resposta',
  'Don\'t validate input': 'Não validar resposta',
  'The user input will not be validated': 'A resposta do usuário não será validada',
  'You can access the user input on the next node with the variable': 'Você pode acessar a resposta do usuário no próximo nó, usando a variável',
  'Date': 'Data',
  'The user input must be a valid CPF. Any other answer will send to the rejected output. You can access the user valid input on the next node with the variable': 'A resposta do usuário deve ser um CPF válido. Qualquer outra resposta será enviada para a saída de erro. Você pode acessar a resposta do usuário no próximo nó, usando a variável',
  'The user input must be a valid CNPJ. Any other answer will send to the rejected output. You can access the user valid input on the next node with the variable': 'A resposta do usuário deve ser um CNPJ válido. Qualquer outra resposta será enviada para a saída de erro. Você pode acessar a resposta do usuário no próximo nó, usando a variável',
  'The user input must be a valid CPF or CNPJ. Any other answer will send to the rejected output. You can access the user valid input on the next node with the variable': 'A resposta do usuário deve ser um CPF ou CNPJ válido. Qualquer outra resposta será enviada para a saída de erro. Você pode acessar a resposta do usuário no próximo nó, usando a variável',
  'Number': 'Números',
  'Location': 'Localização',
  'After sending the elements on this node, the flow will continue automatically to the next node': 'Após enviar os elementos deste nó, o fluxo irá continuar automaticamente para o próximo nó',
  'CPF or CNPJ': 'CPF ou CNPJ',
  'My CNPJ is': 'Meu CNPJ é',
  'The user input must be a valid date on the format DD/MM/YY or DD/MM/YYYY. Any other answer will send to the rejected output. You can access the user valid input on the next node with the variable': 'A resposta do usuário deve ser uma data válida no formato DD/MM/YY ou DD/MM/YYYY. Qualquer outra resposta será enviada para a saída de erro. Você pode acessar a resposta do usuário no próximo nó, usando a variável',
  'Wait user input when you are asking a question to the user': 'Aguarde uma resposta do usuário quando você estiver fazendo uma pergunta',
  'The flow will continue after the user answer something to the chatbot': 'O fluxo irá continuar após o usuário responder alguma coisa para o chatbot',
  'Phone': 'Número de Celular',
  'Landline': 'Número Fixo',
  'Triggers': 'Gatilhos',
  'You can add a default response (will be aplied to all channels), or customize for a specific channel': 'Você pode adicionar uma resposta default (será aplicada para todos os canais), ou customizar a resposta para um canal específico',
  'Add translate': 'Adicionar tradução',
  'Actions': 'Ações',
  'Answers': 'Respostas',
  'Integrations': 'Integrações',
  'Human attendance': 'Atendimento humano',
  'Flow control': 'Controle de fluxo',
  'My CPF is': 'Meu CPF é',
  'Handle out of context message': 'Tratar mensagem fora de contexto',
  'Rejected examples:': 'Exemplos rejeitados:',
  'Accepted examples:': 'Exemplos aceitos:',
  'This node will also send an event to Google Analytics if the Google Analytics integration is enabled on this account': 'Este nó irá enviar um evento para o Google Analytics caso esta integração esteja habilitada em sua conta',
  'The event sent will be as follows:': 'O evento será enviado da seguinte forma',
  'Event Category: Flow - Flow name (Example: Fluxo - xyz)': 'Categoria do evento: Fluxo - Nome do fluxo (Exemplo: Fluxo - abc)',
  'Event Action: Node name (Example: Chat - Answer 1)': 'Ação do evento: Nome do nó (Exemplo: Chat - Resposta 1)',
  'Event value: 1': 'Valor do evento: 1',
  'Send analytics event': 'Enviar evento para o Google Analytics',
  'Analytics event': 'Evento do G.A.',
  'Phone or landline': 'Número de celular ou fixo',
  'Digits': 'Dígitos',
  'File': 'Arquivo',
  'Email': 'Email',
  'More info soon!': 'Mais informações em breve',
  'Change password': 'Alterar senha',
  'Password': 'Senha',
  "Section": "Seção",
  "List open title (Max 20 characters)": "Título para abrir a lista (Até 20 caracteres)",
  "First section (Max 20 characters)": "Primeira seção (Até 20 caracteres)",
  "Fifth section (Max 20 characters)": "Quinta seção (Até 20 caracteres)",
  "Fourth section (Max 20 characters)": "Quarta seção (Até 20 caracteres)",
  "Third section (Max 20 characters)": "Terceira seção (Até 20 caracteres)",
  "Second section (Max 20 characters)": "Segunda seção (Até 20 caracteres)",
  'Add section': 'Adicionar seção',
  'Remove section': 'Remover seção',
  'Confirm password': 'Confirmar senha',
  'Reset password': 'Resetar senha',
  'Enter a new password below for your account. The password must be at least 6 characters long.': 'Insira a nova senha para a sua conta. A senha deve conter ao menos 6 caracteres.',
  'Regular Expression': 'Expressão regular',
  'Zoom out': 'Menos zoom',
  'Zoom in': 'Mais zoom',
  'Zoom to fit': 'Caber na tela',
  'The user will be redirected to the out of context output, when he doesn\'t answer any of the available options on this node, and don\'t trigger any AI intent': 'O usuário será direcionado para a saída da mensagem fora de contexto quando ele não responder nenhuma resposta válida disponível neste nó, e não acionar nenhuma intenção de IA (caso esteja habilitado)',
  'You can use it to question again a question when the user don\'t click/answer a valid option': 'Você pode utilizar isso para perguntar novamente uma pergunta quando o usuário não clicar/responder uma das opções disponíveis',
  'Image': 'Imagem',
  'Edit your variable': 'Edite o valor da sua variável',
  'Edit variable': 'Editar variável',
  'Secret variable (Password and sensitive information)': 'Variável sensível (Senha ou outras informações)',
  'Add new variable': 'Crie uma nova variável',
  'Remove variable': 'Remover variável',
  'Create new environment variable to use in any flow with': 'Crie uma nova variável de ambiente para usar em qualquer fluxo digitando',
  'New variable': 'Nova variável',
  'Do you really want to remove variable': 'Você realmente quer remover a variável',
  'You can use the variables anywhere in the flow using': 'Você pode usar a variável em qualquer lugar do fluxo digitando',
  'Choose a conversation': 'Escolha uma conversa para iniciar',
  'You can follow up your bot conversations in real time': 'Você pode acompanhar as conversas do chatbot em tempo real',
  'Sticker': 'Figurinha',
  'Choose which logs do you want to see, your bot conversations or API logs of your integrations': 'Escolha quais logs você deseja acessar. Logs das conversas de chatbot, ou log de API das suas integrações',
  'Login With Facebook': 'Logar com Facebook',
  'Enable a chatbot on your page': 'Habilite um chatbot em sua página',
  'Connect your facebook account to list and link your pages with this bot': 'Conecte a sua conta do Facebook para listar e vincular as suas páginas a este bot',
  'Add new Facebook Messenger/Instagram page': 'Adicione uma nova página do Facebook Messenger/Instagram',
  'Full screen': 'Tela cheia',
  'Create new flow': 'Criar novo fluxo',
  'Create a new chatbot/API flow': 'Criar um novo fluxo de chatbot/API',
  'Delay in seconds (between 1 and 10)': 'Delay em segundos (Entre 1 e 10)',
  'Insert the text that the chatbot will send to the user': 'Insira o texto que o chatbot irá enviar para o usuário',
  'File URL': 'URL do arquivo',
  'File type': 'Tipo do arquivo',
  'Other': 'Outro',
  'File name': 'Nome do arquivo',
  'File name (Optional)': 'Nome do arquivo (Opcional)',
  'Contact Name': 'Nome do Contato',
  'Contact Phone': 'Telefone do Contato',
  'Country Code': 'Código do País',
  'Add elements to your conversation': 'Adicione elementos a sua conversa',
  'Export flow conversations': 'Exportar mensagens dos flows',
  'SAVE': 'SALVAR',
  'Registered at': "Registrado em",
  "The default flow is activated when a user send a message to any connected channel, that doesn\'t trigger an intent": "O fluxo default é ativado quando um usuário envia uma mensagem para qualquer canal conectado, que não ativa uma intenção",
  'Save': 'Salvar',
  "Search by name or email": "Busque por nome ou email",
  "User email": "Email do novo usuário",
  "Permissions": "Permissões",
  "Invited at": "Convidado em",
  "No invites found": "Nenhum convite encontrado",
  "Here are listed all users that can manage your bot": "Usuários que tem acesso a este projeto",
  "Text": "Texto",
  "Confidence": "Confiança",
  "Date": "Data",
  "Invite user": "Convidar usuário",
  "Here you can invite users to access this bot, the user will receive a email with the signup link": "Convite novos usuários para acessar este projeto. O usuário convidado receberá um email com o link para se registrar na plataforma.",
  "Default flow": "Fluxo default",
  "Link triggered flow": "Fluxo ao clicar em link",
  "Manage users": "Gerenciar usuários",
  "Invite users": "Convidar usuários",
  "Invite will be expired in 2 days": "O convite será expirado em 48 horas.",
  "Ps:": "Obs:",
  "Credentials": "Credenciais",
  "Intents": "Intenções",
  "Entities": "Entidades",
  "Curator": "Curadoria",
  "Unhandled Intents": "Intenções não entendidas",
  "Examples": "Exemplos",
  'User info': 'Informações do usuário',
  'Edit user info': 'Editar informações do usuário',
  'Publish flows': 'Publicar fluxos',
  'NLP - Natural Language Processing (AI)': "PLN - Processador de linguagem natural (IA)",
  'Manage your NLP connection, intents, entities and curatorship': "Gerencie o seu conector de PLN, intenções, entidades e curadoria",
  "Batches": "Lotes",
  "Created at": "Criado em",
  "Broadcast": "Transmissão",
  "Edit broadcast data, status and users": "Gerencie os dados da transmissão e os seus usuários",
  "Short Description": "Descrição",
  "Info": "Informações",
  "Loading Broadcast Data": "Carregando os dados da transmissão",
  "Choose a flow": "Escolha um fluxo",
  "The selected flow, will be started for each user starting from the \"Start node\"": "O fluxo escolhido, será iniciado para cada usuário da transmissão",
  "Flow": "Fluxo",
  "Start (Leave empty to start immediately)": "Início (Deixe em branco para iniciar imediatamente)",
  "Batch size": "Quantidade de usuários por lote",
  "Interval in minuts between batches": "Intervalo em minutos entre cada lote",
  "Broadcast hours interval": "Intervalo em horas para o disparo",
  "Start": "Início",
  "End": "Fim",
  "The broadcast will resume on the day after, inside the hour interval, if the broadcast is not completed": "Caso não seja finalizada no mesmo dia, a transmissão irá continuar no dia seguinte, dentro do horário permitido",
  "Download the template": "Baixe o template",
  "here": "aqui",
  "Broadcast channel": "Canal da transmissão",
  "File delimiter": "Delimitador do arquivo",
  "Values separated by": "Valores separados por",
  "SAVE": "SALVAR",
  "ENVIAR": "ENVIAR",
  "Broadcast name": "Nome da transmissão",
  "DRAFT": "RASCUNHO",
  "PENDING": "PENDENTE",
  "SCHEDULED": "AGENDADA",
  "SENDED": "ENVIADA",
  "INTERRUPTED": "INTERROMPIDA",
  "Send broadcast": "Enviar transmissão",
  "Do you really want save the changes and broadcast this to users?": "Você realmente deseja salvar as alterações e enviar essa transmissão para os usuários?",
  "from csv": "do csv",
  "users": "usuários",
  "Users in .csv": "Usuários no .csv",
  "Drag and drop file, or click to search": "Arraste e solte o arquivo .csv, ou clique para pesquisar em seu computador",
  "users found": "usuários encontrados",
  "Please, select the broadcast channel": "Por favor, escolha um canal para a transmissão",
  "Broadcasts": "Transmissões",
  "Create and manage your broadcasts to reach more users": "Crie e gerencie as suas transmissões para alcançar mais usuários",
  "Created by": "Criado por",
  "No broadcasts found": "Nenhuma transmissão encontrada",
  "Refresh broadcast": "Atualizar transmissões",
  "Refresh": "Atualizar",
  "Are you sure you want to remove bot": "Você tem certeza que deseja remover o bot",
  "Create broadcast": "Criar transmissão",
  "Do you really want to remove broadcast": "Você realmente deseja remover a transmissão",
  "Do you really want to interrupt broadcast": "Você realmente deseja interromper a transmissão",
  "Options": "Opções",
  "Sended at": "Enviado em",
  "Sended/Total": "Enviado/Total",
  "Finished at": "Finalizado em",
  "Create new active broadcast for your users": "Crie uma nova transmissão para os seus usuários",
  "Add new broadcast": "Criar nova transmissão",
  "Include CSV file to import users": "Insira um arquivo CSV para importar os usuários",
  "The CSV file must contain at least 1 record with field \"id\" required, be shure of the correct separator for your document": "O arquivo importado deve conter o no mínimo 1 registro com o campo \"id\", certifique-se de incluir o separador correto para o seu arquivo csv",
  "\"id\" is required for all lines, you have an empty id on line ": "Todas as linhas devem ter um id válido, você tem um erro na linha ",
  "Broadcast start time must be later then now": "A data de início da transmissão deve ser depois da data e hora atual",
  "Interrupt broadcast": "Interromper transmissão",
  "Remove broadcast": "Remover transmissão",
  "Cancel": "Cancelar",
  "Confirm": "Confirmar",
  "Range in hours to send batches (Brazil local time)": "Intervalo em horas para o disparo. Horário de Brasília",
  "Forgot Password": "Esqueci a senha",
  "6 digits code": "Código de 6 dígitos",
  "Two factor authentication": "Autenticação de 2 fatores",
  "Enter the code sended to your email": "Insira o código enviado para o seu email",
  "Password": "Senha",
  "Sign In": "Entrar",
  "Enter your registered email below to receive instructions on how to reset your password": "Insira o email registrado para receber instruções de como resetar a sua senha",
  "Send": "Enviar",
  "Save": "Salvar",
  "Saved": "Salvo",
  "Saving": "Salvando",
  "Export": "Exportar",
  "Previous Builds": "Versões Anteriores",
  "Publish": "Publicar",
  "You can reset your draft to a previous build": "Você pode resetar seu rascunho para uma versão anterior",
  "has published": "publicou",
  "at": "em",
  "Reset": "Resetar",
  "Reseting": "Resetando",
  "Publish flow": "Publicar fluxo",
  "Deploy your flow": "Implanta seu fluxo",
  "errors": "erros",
  "All links must be connected": "Todos os links devem estar conectados",
  "Flow validated successfully": "Fluxo validado com sucesso",
  "Select the environments to publish": "Selecione os ambientes para publicar",
  "Build Name": "Nome da versão",
  "Description": "Descrição",
  "There aren't previous publishes to list": "Não há publicações anteriores",
  "Empty description": "Descrição vazia",
  "Loading": "Carregando",
  "Clone flow": "Clonar fluxo",
  "Clone": "Clonar",
  "Name": "Nome",
  "Flow cannot be published because of the following errors": "O fluxo não pode ser publicado pelos seguintes erros",
  "cannot be published because of the following errors": "não pode ser publicado pelos seguintes erros",
  "Running flow validator": "Executando validor de fluxos",
  "Publishing": "Publicando",
  "Publishing flows": "Publicando fluxos",
  "Flow published successfully": "Fluxo publicado com sucesso",
  "Waiting": "Esperando",
  "The flows are being published": "Os fluxos estão sendo publicados",
  "Close": "Fechar",
  "Flows validated successfully": "Fluxos validados com sucesso",
  "Running flows validators": "Executando validadores de fluxos",
  "Loading Flow Editor": "Carregando Editor de Fluxo",
  "Drag and drop a node to insert it into the flow": "Arraste e solte um nó para inserir no fluxo",
  "List of flows that access the current flow": "Lista de fluxos que acessam o fluxo atual",
  "Clear filters": "Limpar filtros",
  "Apply filters": "Aplicar filtros",
  "Channel": "Canal",
  "Contact": "Contato",
  "Last message": "Última mensagem",
  "User props": "Variáveis de usuário",
  "Property": "Variável",
  "Condition": "Condição",
  "Value": "Valor",
  "No conversations founded": "Nenhuma conversa encontrada",
  "Manage your bot info": "Gerencie as informações do seu bot",
  "Bot details": "Detalhes do bot",
  "Change avatar": "Alterar foto",
  "Description": "Descrição",
  "Update": "Atualizar",
  "Manage your integrations": "Gerencie as suas integrações",
  "Integrations List": "Lista de integrações",
  "Loading integrations": "Carregando integrações",
  "CONNECT": "CONECTAR",
  "CONNECTED": "CONECTADO",
  "Fill with default channel": "Preencher com canal default",
  "Are you sure you want to fill with default channel?": "Tem certeza que deseja preencher com o canal default?",
  "This action will overwrite all changes already made": "Essa ação irá sobreescrever todas as alterações já feitas",
  "Choose the range time for your broadcast to run": "Escolha o intervalo de horas que sua transmissão será executada",
  "Add Telegram channel": "Adicionar canal Telegram",
  "Fill the Telegram credentials below": "Preencha as credencias do Telegram abaixo",
  "Update Telegram channel": "Atualizar canal Telegram",
  "Telegram channels": "Canais Telegram",
  "Update existing channels or add new Telegram channel": "Atualize os canais existentes, ou adicione um novo canal Telegram",
  "Broadcast added successfully": "Transmissão adicionada com sucesso",
  "Broadcast removed successfully": "Transmissão removida com sucesso",
  "Broadcast updated successfully": "Transmissão atualizada com sucesso",
  "Broadcast file uploaded successfully": "Arquivo importado com sucesso",
  "Broadcast updated and sended successfully": "Transmissão atualizada e enviada com sucesso",
  "Preview first rows": "Preview primeiras linhas",
  "The file has duplicated columns": "O arquivo possui colunas duplicadas",
  "The detected delimiter is": "O separador detectado é",
  "Chat placement": "Posição do Chat",
  "Popup placement": "Posição do Popup",
  "Can close chat\'s popup": "Pode fechar o popup do chat",
  "Password must meet following criteria": "A senha deve atender aos seguintes critérios",
  "Minimum of 8 characters, max of 20": "Mínimo 8 caracteres, máximo 20",
  "At least one uppercase": "Pelo menos 1 maiúscula",
  "Required at least one uppercase": "Deve conter pelo menos 1 maiúscula",
  "At least one lowercase": "Pelo menos 1 minúscula",
  "Required at least one lowercase": "Deve conter pelo menos 1 minúscula",
  "At least one digit": "Pelo menos 1 número",
  "Required at least one digit": "Deve conter pelo menos 1 número",
  "Remove flow": "Remover fluxo",
  "Do you really want to remove flow": "Tem certeza que deseja remover o fluxo",
  "This action is irreversible": "Essa ação é irreversível",
  "Type the flow\'s name": "Digite o nome do fluxo",
  "Flow name does not match": "O nome do fluxo não confere",
  "File": "Arquivo",
  "No flow": "Nenhum fluxo",
  "No channel": "Nenhum canal",
  "No file": "Nenhum arquivo",
  "Immediate start": "Início imediato",
  "Active broadcasts": "Transmissões ativas",
  "Removed broadcasts": "Transmissões removidas",
  "Start date": "Data de início",
  "Minutes between batches": "Minutos entre lotes",
  "Range in hours": "Intervalo de horas",
  "Last status": "Último status",
  "The passwords must be equal": "As senhas devem ser iguais",
  "Export stats": "Exportar stats",
  "Error to generate excel file": "Erro ao gerar o arquivo excel",
  "Error to export data": "Erro ao exportar os dados",
  "The file has duplicated columns": "O arquivo possui colunas duplicadas",
  "The file has empty columns": "O arquivo possui colunas vazias",
  "position": "posição"
}
