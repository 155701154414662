import React, { Suspense, Component, Fragment } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { lazyWithRetry } from "./lazyReload"
import toastActions from "states/toast/action"
import { Router, Route, Switch, Redirect } from "react-router-dom"
import Snackbar from "@material-ui/core/Snackbar"

const Login = lazyWithRetry(() => import("containers/Auth/Login"))
const ForgotPassword = lazyWithRetry(() =>
  import("containers/Auth/ForgotPassword")
)
const Signup = lazyWithRetry(() => import("containers/Auth/Signup"))
const Privacy = lazyWithRetry(() => import("containers/Auth/Privacy"))
const Bot = lazyWithRetry(() => import("containers/Bot"))
const JSX = lazyWithRetry(() => import("containers/JSX"))

const OpenRoute = (props) => {
  const token = localStorage.getItem("token")
  return token ? <Redirect to="/bots" /> : <Route {...props} />
}

const AuthRoute = (props) => {
  const token = localStorage.getItem("token")
  return token ? <Route {...props} /> : <Redirect to="/" />
}

class AppRouter extends Component {
  handleClose = () => {
    const { actions } = this.props
    actions.showToast(null)
  }

  render = () => {
    const { history, toast, durationToast } = this.props
    return (
      <Fragment>
        <Suspense fallback={<></>}>
          <Router history={history}>
            <Switch>
              <OpenRoute path="/forgot-password" component={ForgotPassword} />
              <OpenRoute path="/comolatti/forgot-password" component={ForgotPassword} />
              <OpenRoute path="/" component={Login} exact={true} />
              <OpenRoute path="/comolatti" component={Login} exact={true} />
              <Route path="/privacy" component={Privacy} />
              <Route path="/signup" component={Signup} exact={true} />
              <Route path="/comolatti/signup" component={Signup} exact={true} />
              <AuthRoute path="/bots" component={Bot} />
              <Route path="/jsx" component={JSX} />
              <Redirect to="/" />
            </Switch>
          </Router>
        </Suspense>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          open={toast != null}
          autoHideDuration={durationToast}
          onClose={this.handleClose}
          onExited={this.handleExited}
          message={toast}
        />
      </Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  toast: state.toast.message,
  durationToast: state.toast.duration,
})

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ ...toastActions }, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(AppRouter)
