import API from 'api'
import { translate } from 'locales/index'
import toastActions from 'states/toast/action'

export const actionTypes = {
  OPEN_ADD_CHANNEL: 'CHANNEL/OPEN_ADD_CHANNEL',
  SET_DIALOG_LOADING: 'CHANNEL/SET_DIALOG_LOADING',
  SET_LOADING: 'CHANNEL/SET_LOADING',
  SET_CHANNELS: 'CHANNEL/SET_CHANNELS',
  INITIAL_STATE: 'CHANNEL/INITIAL_STATE'
}

export const actions = {
  addChannel: body => async (dispatch, getState) => {
    const { environment, bot } = getState()
    const botId = bot.bot._id
    const environmentId = environment.environment._id
    dispatch(actions.setDialogLoading(true))
    try {
      await API.addChannel(botId, environmentId, body)
      dispatch(actions.getChannels())
      dispatch(toastActions.showToast('Channel added successfully.'))
    } catch (error) {
      const message = error.response?.data?.message || 'Could not add channel'
      dispatch(toastActions.showToast(message))
    } finally {
      dispatch(actions.setDialogLoading(false))
    }
  },
  getChannels: () => async (dispatch, getState) => {
    const { environment, bot } = getState()
    const botId = bot.bot._id
    const environmentId = environment.environment._id
    dispatch(actions.setLoading(true))
    try {
      const { data } = await API.getChannels(botId, environmentId, '', 1, 100)
      dispatch(actions.setChannels(data))
    } catch (error) {
    } finally {
      dispatch(actions.setLoading(false))
    }
  },
  removeChannel: id => async (dispatch, getState) => {
    const { environment, bot } = getState()
    const botId = bot.bot._id
    const environmentId = environment.environment._id
    dispatch(actions.setDialogLoading('removing'))
    try {
      await API.removeChannel(botId, environmentId, id)
      dispatch(actions.getChannels())
      dispatch(toastActions.showToast(translate('Channel removed successfully')))
    } catch (error) {
      const message = error.response?.data?.message || 'Unexpected error.'
      dispatch(toastActions.showToast(message))
    } finally {
      dispatch(actions.setDialogLoading(false))
    }
  },
  updateChannel: (id, body) => async (dispatch, getState) => {
    const { environment, bot } = getState()
    const botId = bot.bot._id
    const environmentId = environment.environment._id
    dispatch(actions.setDialogLoading('updating'))
    try {
      await API.updateChannel(botId, environmentId, id, body)
      dispatch(actions.getChannels())
      dispatch(toastActions.showToast('Channel updated successfully.'))
    } catch (error) {
      const message = error.response?.data?.message || 'Unexpected error.'
      dispatch(toastActions.showToast(message))
    } finally {
      dispatch(actions.setDialogLoading(false))
    }
  },
  clearQueueChannel: id => async (dispatch, getState) => {
    const { environment, bot } = getState()
    const botId = bot.bot._id
    const environmentId = environment.environment._id
    dispatch(actions.setDialogLoading('cleaningLine'))
    try {
      await API.clearQueueChannel(botId, environmentId, id)
      dispatch(actions.getChannels())
      dispatch(toastActions.showToast('Clear queue sended successfully.'))
    } catch (error) {
      const message = error.response?.data?.message || 'Unexpected error.'
      dispatch(toastActions.showToast(message))
    } finally {
      dispatch(actions.setDialogLoading(false))
    }
  },
  setLoading: value => ({ type: actionTypes.SET_LOADING, value }),
  setChannels: data => ({ type: actionTypes.SET_CHANNELS, data }),
  openAddChannel: (value, channel) => ({ type: actionTypes.OPEN_ADD_CHANNEL, value, selectedChannel: channel }),
  setDialogLoading: value => ({ type: actionTypes.SET_DIALOG_LOADING, value }),
  initialState: () => (dispatch) => {
    dispatch({ type: actionTypes.INITIAL_STATE })
  }
}

export default actions
