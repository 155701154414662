import { createReducer } from '../../reducers'
import { actionTypes } from './action'

export const initialState = () => ({
  loading: false,
  loadingPaginated: false,
  dialogLoading: false,
  bot: null,
  bots: [],
  botsPaginated: [],
  countPaginated: 0,
  invites: [],
  invitesCount: 0,
  botUsers: [],
  botUsersCount: 0,
  openAddBot: false,
  openAddInvite: false
})

export const reducer = {
  [actionTypes.SET_LOADING](state, { value }) {
    return { ...state, loading: value }
  },
  [actionTypes.SET_LOADING_PAGINATED](state, { value }) {
    return { ...state, loadingPaginated: value }
  },
  [actionTypes.SET_DIALOG_LOADING](state, { value }) {
    return { ...state, dialogLoading: value }
  },
  [actionTypes.OPEN_ADD_BOT](state, { value }) {
    return { ...state, openAddBot: value }
  },
  [actionTypes.OPEN_ADD_INVITE](state, { value }) {
    return { ...state, openAddInvite: value }
  },
  [actionTypes.SET_BOT](state, { data }) {
    return { ...state, bot: data }
  },
  [actionTypes.SET_BOT_USERS](state, { data }) {
    return { ...state, botUsers: data.rows, botUsersCount: data.count }
  },
  [actionTypes.SET_INVITES](state, { data }) {
    return { ...state, invites: data.rows, invitesCount: data.count }
  },
  [actionTypes.SET_BOTS](state, { data }) {
    return { ...state, bots: data.rows }
  },
  [actionTypes.SET_BOTS_PAGINATED](state, { data }) {
    return { ...state, botsPaginated: data.rows, countPaginated: data.count }
  },
  [actionTypes.INITIAL_STATE]() {
    return { ...initialState() }
  }
}

export default createReducer(initialState(), reducer)
