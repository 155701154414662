import { createReducer } from '../../reducers'
import { actionTypes } from './action'

export const initialState = {
  loading: false,
  openAddVariable: false,
  openEditVariable: false,
  dialogLoading: false,
  editVariable: {}
}

export const reducer = {
  [actionTypes.SET_LOADING](state, { value }) {
    return { ...state, loading: value }
  },
  [actionTypes.INITIAL_STATE]() {
    return { ...initialState }
  },
  [actionTypes.OPEN_ADD_VARIABLE](state, { value }) {
    return { ...state, openAddVariable: value }
  },
  [actionTypes.OPEN_EDIT_VARIABLE](state, { value }) {
    return { ...state, openEditVariable: value }
  },
  [actionTypes.SET_DIALOG_LOADING](state, { value }) {
    return { ...state, dialogLoading: value }
  },
  [actionTypes.SET_EDIT_VARIABLE](state, { value }) {
    return { ...state, editVariable: value }
  }
}

export default createReducer(initialState, reducer)
