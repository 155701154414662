import { createReducer } from '../../reducers'
import { actionTypes } from './action'

export const initialState = () => ({
  loading: false,
  dialogLoading: false,
  user: null,
  tenant: null
})

export const reducer = {
  [actionTypes.SET_LOADING](state, { value }) {
    return { ...state, loading: value }
  },
  [actionTypes.SET_DIALOG_LOADING](state, { value }) {
    return { ...state, dialogLoading: value }
  },
  [actionTypes.SET_USER_FB](state, { data }) {
    return { ...state, user: { ...state.user, fbCredentials: data.fbCredentials } }
  },
  [actionTypes.SET_USER](state, { data }) {
    if (!state.tenant) {
      const [tenant] = data.tenants
      localStorage.setItem('tenant', JSON.stringify(tenant))
      sessionStorage.setItem('tenant', JSON.stringify(tenant))
      sessionStorage.setItem('tenantId', tenant._id)
      state.tenant = tenant
    }
    return { ...state, user: data }
  },
  [actionTypes.SET_TENANT](state, { tenant }) {
    localStorage.setItem('tenant', JSON.stringify(tenant))
    sessionStorage.setItem('tenant', JSON.stringify(tenant))
    sessionStorage.setItem('tenantId', tenant._id)
    return { ...state, tenant }
  },
  [actionTypes.INITIAL_STATE]() {
    return initialState()
  }
}

export default createReducer(initialState(), reducer)
