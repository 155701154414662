import API from 'api'
import toastActions from 'states/toast/action'
import transcriptorActions from 'states/transcriptor/action'
import conversationActions from 'states/conversations/action'

export const actionTypes = {
  SET_LOADING: 'INTENT/SET_LOADING',
  SET_CONTEXT_LOADING: 'INTENT/SET_CONTEXT_LOADING',
  SET_CONTEXT_MESSAGES: 'INTENT/SET_CONTEXT_MESSAGES',
  SET_UNHANDLED_LOADING: 'INTENT/SET_UNHANDLED_LOADING',
  SET_DIALOG_LOADING: 'INTENT/SET_DIALOG_LOADING',
  SET_INTENTS: 'INTENT/SET_INTENTS',
  SET_AUTOCOMPLETE_INTENTS: 'INTENT/SET_AUTOCOMPLETE_INTENTS',
  SET_UNHANDLED_INTENTS: 'INTENT/SET_UNHANDLED_INTENTS',
  SET_INTENT: 'INTENT/SET_INTENT',
  OPEN_IGNORE_SAMPLES: 'INTENT/OPEN_IGNORE_SAMPLES',
  OPEN_ADD_INTENT: 'INTENT/OPEN_ADD_INTENT',
  OPEN_ADD_SAMPLE: 'INTENT/OPEN_ADD_SAMPLE',
  OPEN_EDIT_INTENT: 'INTENT/OPEN_EDIT_INTENT',
  INITIAL_STATE: 'INTENT/INITIAL_STATE'
}

export const actions = {
  getIntents: (search = '', page = 0, limit = 10, sort) => async (dispatch, getState) => {
    const { environment, bot } = getState()
    const botId = bot?.bot?._id
    const environmentId = environment?.environment?._id
    dispatch(actions.setLoading(true))
    try {
      const { data } = await API.getIntents(botId, environmentId, search, page + 1, limit, sort)
      dispatch(actions.setIntents(data))
    } catch (error) {
    } finally {
      dispatch(actions.setLoading(false))
    }
  },
  getUnhandledIntents: (search = '', page = 0, limit = 10, sort) => async (dispatch, getState) => {
    const { environment, bot } = getState()
    const botId = bot.bot._id
    const environmentId = environment.environment._id
    dispatch(actions.setUnhandledLoading(true))
    try {
      const { data } = await API.getUnhandledIntents(botId, environmentId, search, page + 1, limit, sort)
      dispatch(actions.setUnhandledIntents(data))
    } catch (error) {
    } finally {
      dispatch(actions.setUnhandledLoading(false))
    }
  },
  getIntentContext: message => async (dispatch, getState) => {
    const { environment, bot } = getState()
    const botId = bot.bot._id
    const environmentId = environment.environment._id
    dispatch(actions.setContextLoading(true))
    try {
      const { data } = await API.getContextMessages(botId, environmentId, message.id)
      dispatch(actions.setContextMessages(data))
    } catch (error) {
    } finally {
      dispatch(actions.setContextLoading(false))
    }
  },
  ignoreIntent: (message, searchParams) => async (dispatch, getState) => {
    const { environment, bot } = getState()
    const botId = bot.bot._id
    const environmentId = environment.environment._id

    dispatch(actions.setUnhandledLoading(true))
    dispatch(actions.setDialogLoading('ignoring'))

    try {
      const { data } = await API.ignoreIntent(botId, environmentId, message.id)
      dispatch(transcriptorActions.updateMessage(data))
      dispatch(conversationActions.updateMessage(data))
      dispatch(actions.updateContextMessage(data))

      dispatch(actions.openAddSample(false))
      searchParams && dispatch(actions.searchUnhandledIntents(searchParams))
    } catch (error) {
      dispatch(actions.setUnhandledLoading(false))
    } finally {
      dispatch(actions.setUnhandledLoading(false))
      dispatch(actions.setDialogLoading(false))
    }
  },
  ignoreSamples: (body, searchParams) => async (dispatch, getState) => {
    const { environment, bot } = getState()
    const botId = bot.bot._id
    const environmentId = environment.environment._id

    dispatch(actions.setUnhandledLoading(true))
    try {
      await API.ignoreSamples(botId, environmentId, body)
      dispatch(actions.openIgnoreSamples(false))
      searchParams && dispatch(actions.searchUnhandledIntents(searchParams))
    } catch (error) {
      dispatch(actions.setUnhandledLoading(false))
    }
  },
  searchUnhandledIntents: searchParams => async (dispatch) => {
    const {
      search, page, limit, order
    } = searchParams
    let { orderBy } = searchParams
    if (order === 'desc') {
      orderBy = `-${orderBy}`
    }
    dispatch(actions.getUnhandledIntents(search, page, limit, orderBy))
  },
  addIntentSample: (body, searchParams) => async (dispatch, getState) => {
    const { environment, bot, intent } = getState()
    const botId = bot.bot._id
    const environmentId = environment.environment._id
    const messageId = intent.openAddSample.id
    dispatch(actions.setDialogLoading('adding'))
    try {
      const { data } = await API.addIntentSample(botId, environmentId, messageId, body)
      dispatch(transcriptorActions.updateMessage(data))
      dispatch(conversationActions.updateMessage(data))
      dispatch(actions.updateContextMessage(data))
      dispatch(actions.openAddSample(false))
      dispatch(actions.getIntents())
      searchParams && dispatch(actions.searchUnhandledIntents(searchParams))
    } catch (error) {
      const message = error.response ?.data ?.message || 'Unexpected error'
      dispatch(toastActions.showToast(message))
    } finally {
      dispatch(actions.setDialogLoading(false))
    }
  },
  addIntent: body => async (dispatch, getState) => {
    const { environment, bot } = getState()
    const botId = bot.bot._id
    const environmentId = environment.environment._id
    dispatch(actions.setDialogLoading(true))
    try {
      const { data } = await API.addIntent(botId, environmentId, body)
      dispatch(actions.openAddIntent(false))
      dispatch(actions.getIntents())
      dispatch(toastActions.showToast('Intent added successfully.'))
    } catch (error) {
    } finally {
      dispatch(actions.setDialogLoading(false))
    }
  },
  removeIntent: id => async (dispatch, getState) => {
    const { environment, bot } = getState()
    const botId = bot.bot._id
    const environmentId = environment.environment._id
    dispatch(actions.setLoading(true))
    try {
      const { data } = await API.removeIntent(botId, environmentId, id)
      dispatch(actions.getIntents())
      dispatch(toastActions.showToast('Intent removed successfully.'))
    } catch (error) {
    } finally {
      dispatch(actions.setLoading(false))
    }
  },
  getIntent: id => async (dispatch, getState) => {
    const { environment, bot } = getState()
    const botId = bot.bot._id
    const environmentId = environment.environment._id
    try {
      const { data } = await API.getIntent(botId, environmentId, id)
      dispatch(actions.setIntent(data))
    } catch (error) {
    } finally {
    }
  },
  updateIntent: (id, body) => async (dispatch, getState) => {
    const { environment, bot } = getState()
    const botId = bot.bot._id
    const environmentId = environment.environment._id
    try {
      dispatch(actions.setDialogLoading(true))
      const { data } = await API.updateIntent(botId, environmentId, id, body)
      dispatch(actions.openEditIntent(false))
      dispatch(actions.getIntents())
      dispatch(toastActions.showToast('Intent updated successfully.'))
    } catch (error) {
    } finally {
      dispatch(actions.setDialogLoading(false))
    }
  },
  getAutoCompleteIntents: () => async (dispatch, getState) => {
    const { environment, bot } = getState()
    const botId = bot.bot._id
    const environmentId = environment.environment._id
    dispatch(actions.setDialogLoading(true))
    try {
      const { data } = await API.getIntents(botId, environmentId, '', 1, 100, 'name')
      dispatch(actions.setAutoCompleteIntents(data))
    } catch (error) {
    } finally {
      dispatch(actions.setDialogLoading(false))
    }
  },
  updateContextMessage: data => async (dispatch, getState) => {
    const { intent } = getState()
    const { contextMessages } = intent

    for (const index in contextMessages) {
      if (contextMessages[index].id === data.id) {
        contextMessages[index] = data
        break
      }
    }

    dispatch(actions.setContextMessages({ rows: [...contextMessages] }))
  },
  setIntents: data => ({ type: actionTypes.SET_INTENTS, data }),
  setAutoCompleteIntents: data => ({ type: actionTypes.SET_AUTOCOMPLETE_INTENTS, data }),
  setUnhandledIntents: data => ({ type: actionTypes.SET_UNHANDLED_INTENTS, data }),
  setContextMessages: data => ({ type: actionTypes.SET_CONTEXT_MESSAGES, data }),
  setIntent: data => ({ type: actionTypes.SET_INTENT, data }),
  openAddIntent: value => ({ type: actionTypes.OPEN_ADD_INTENT, value }),
  openAddSample: value => (dispatch) => {
    value && dispatch(actions.getAutoCompleteIntents())
    dispatch({ type: actionTypes.OPEN_ADD_SAMPLE, value })
  },
  openIgnoreSamples: value => ({ type: actionTypes.OPEN_IGNORE_SAMPLES, value }),
  openEditIntent: value => async (dispatch) => {
    dispatch({ type: actionTypes.OPEN_EDIT_INTENT, value })
    value && dispatch(actions.getIntent(value))
  },
  setLoading: value => ({ type: actionTypes.SET_LOADING, value }),
  setContextLoading: value => ({ type: actionTypes.SET_CONTEXT_LOADING, value }),
  setUnhandledLoading: value => ({ type: actionTypes.SET_UNHANDLED_LOADING, value }),
  setDialogLoading: value => ({ type: actionTypes.SET_DIALOG_LOADING, value }),
  initialState: () => (dispatch) => {
    dispatch({ type: actionTypes.INITIAL_STATE })
  }
}

export default actions
