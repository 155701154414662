import API from 'api'
import toastActions from 'states/toast/action'
import { push, replace } from 'react-router-redux'

export const actionTypes = {
  SET_ENVIRONMENTS: 'ENVIRONMENT/SET_ENVIRONMENTS',
  SET_ENVIRONMENT: 'ENVIRONMENT/SET_ENVIRONMENT',
  SET_LOADING: 'ENVIRONMENT/SET_LOADING',
  SET_DIALOG_LOADING: 'ENVIRONMENT/SET_DIALOG_LOADING',
  OPEN_ADD_ENVIRONMENT: 'ENVIRONMENT/OPEN_ADD_ENVIRONMENT',
  OPEN_ENVIRONMENT_SETTINGS: 'ENVIRONMENT/OPEN_ENVIRONMENT_SETTINGS',
  INITIAL_STATE: 'ENVIRONMENT/INITIAL_STATE'

}

export const actions = {
  createEnvironment: body => async (dispatch, getState) => {
    const state = getState()
    const bot = state.bot.bot
    dispatch(actions.setLoading(true))
    try {
      const { data } = await API.createEnvironment(bot._id, body)
      dispatch(actions.openAddEnvironment(false))
      dispatch(actions.setEnvironment(data))
      dispatch(actions.getEnvironments(bot._id))
      dispatch(toastActions.showToast('Environment created successfully.'))
    } catch (error) {
      console.log(error)
    } finally {
      dispatch(actions.setLoading(false))
    }
  },
  updateEnvironment: body => async (dispatch, getState) => {
    const state = getState()
    const bot = state.bot.bot
    const environment = state.environment.environment
    dispatch(actions.setLoading(true))
    try {
      const { data } = await API.updateEnvironment(bot._id, environment._id, body)
      dispatch(actions.openEnvironmentSettings(false))
      dispatch(actions.setEnvironment(data))
      dispatch(actions.getEnvironments(bot._id))
      dispatch(toastActions.showToast('Environment updated successfully.'))
    } catch (error) {
      console.log(error)
    } finally {
      dispatch(actions.setLoading(false))
    }
  },
  getEnvironments: botId => async (dispatch) => {
    dispatch(actions.setLoading(true))
    try {
      const { data } = await API.getEnvironments(botId)
      dispatch(actions.setEnvironments(data))
    } catch (error) {
      dispatch(toastActions.showToast('Ops... Bot não encontrado'))
      dispatch(replace('/'))
    } finally {
      dispatch(actions.setLoading(false))
    }
  },
  updateInactivity: body => async (dispatch, getState) => {
    const state = getState()
    const bot = state.bot.bot
    const environment = state.environment.environment

    dispatch(actions.setLoading('inactivity'))
    try {
      const { data } = await API.updateInactivity(bot._id, environment._id, body)
      dispatch(actions.setEnvironment(data))
      dispatch(toastActions.showToast('Environment inactivity updated successfully.'))
    } catch (error) { } finally {
      dispatch(actions.setLoading(false))
    }
  },
  setDefaultFlow: body => async (dispatch, getState) => {
    const state = getState()
    const bot = state.bot.bot
    const environment = state.environment.environment

    dispatch(actions.setLoading('setting'))
    try {
      const { data } = await API.setDefaultFlow(bot._id, environment._id, body)
      dispatch(toastActions.showToast('Default flow setted successfully.'))
      dispatch(actions.setEnvironment(data))
    } catch (error) { } finally {
      dispatch(actions.setLoading(false))
    }
  },
  unsetDefaultFlow: () => async (dispatch, getState) => {
    const state = getState()
    const bot = state.bot.bot
    const environment = state.environment.environment

    dispatch(actions.setLoading('unsetting'))
    try {
      const { data } = await API.unsetDefaultFlow(bot._id, environment._id)
      dispatch(toastActions.showToast('Default flow unsetted successfully.'))
      dispatch(actions.setEnvironment(data))
    } catch (error) { } finally {
      dispatch(actions.setLoading(false))
    }
  },
  setLinkFlow: body => async (dispatch, getState) => {
    const state = getState()
    const bot = state.bot.bot
    const environment = state.environment.environment

    dispatch(actions.setLoading('setting'))
    try {
      const { data } = await API.setLinkFlow(bot._id, environment._id, body)
      dispatch(toastActions.showToast('Link clicked flow setted successfully.'))
      dispatch(actions.setEnvironment(data))
    } catch (error) { } finally {
      dispatch(actions.setLoading(false))
    }
  },
  unsetLinkFlow: () => async (dispatch, getState) => {
    const state = getState()
    const bot = state.bot.bot
    const environment = state.environment.environment

    dispatch(actions.setLoading('unsetting'))
    try {
      const { data } = await API.unsetLinkFlow(bot._id, environment._id)
      dispatch(toastActions.showToast('Link clicked flow unsetted successfully.'))
      dispatch(actions.setEnvironment(data))
    } catch (error) { } finally {
      dispatch(actions.setLoading(false))
    }
  },
  setEnvironments: data => ({ type: actionTypes.SET_ENVIRONMENTS, data }),
  setEnvironment: data => ({ type: actionTypes.SET_ENVIRONMENT, data }),
  openAddEnvironment: value => ({ type: actionTypes.OPEN_ADD_ENVIRONMENT, value }),
  openEnvironmentSettings: value => ({ type: actionTypes.OPEN_ENVIRONMENT_SETTINGS, value }),
  setLoading: value => ({ type: actionTypes.SET_LOADING, value }),
  setDialogLoading: value => ({ type: actionTypes.SET_DIALOG_LOADING, value }),
  initialState: () => (dispatch) => {
    dispatch({ type: actionTypes.INITIAL_STATE })
  }
}

export default actions
