export const theme = {
  primary: '#388FCA',
  secondary: '#00295E',
  light: '#2768c533',
  dark: '#233145',
  warn: '#9a0007',
  error: '#9a0007',
  sand: '#2768c533',
  shell: '#2768c533',
  danger: '#9a0007',
  white: '#ffffff'
}

export default theme
