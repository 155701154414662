import API from 'api'
import { encode } from 'base-64'
import { push, replace } from 'react-router-redux'
import userActions from 'states/user/action'
import environmentActions from 'states/environment/action'
import { getWhitelabel } from 'utils/whitelabel'
import toastActions from 'states/toast/action'

export const actionTypes = {
  LOGIN: 'AUTH/LOGIN',
  SET_LOADING: 'AUTH/SET_LOADING',
  SET_INVITE: 'AUTH/SET_INVITE',
  SET_REFRESH: 'AUTH/SET_REFRESH',
  SET_FORGOT: 'AUTH/SET_FORGOT',
  SET_FORGOT_PASSWORD: 'AUTH/SET_FORGOT_PASSWORD',
  SET_FORGOT_LOADING: 'AUTH/SET_FORGOT_LOADING',
  SET_TWO_FACTOR: 'AUTH/SET_TWO_FACTOR',
  SET_TWO_FACTOR_LOADING: 'AUTHSET_TWO_FACTOR_LOADING'
}

export const authActions = {
  loginWithoutCaptcha: body => async (dispatch) => {
    dispatch(authActions.setLoading(true))
    try {
      const { email, password, ...rest } = body
      const hash = `Basic ${encode(`${email}:${password}`)}`
      const { data } = await API.login(hash, rest)
      localStorage.setItem('token', data.token)
      localStorage.setItem('refreshToken', data.refreshToken)
      localStorage.setItem('user', JSON.stringify(data.user))
      localStorage.setItem('hyperflow-diagram-nodes', null)
      dispatch(userActions.setUser(data.user))
      dispatch(push('/bots'))
    } catch (error) {
      if (error?.response?.data?.code === "NO_ACCESS") {
        dispatch(toastActions.showToast('Sua conta foi desativada. Para mais informações entre em contato com o responsável em sua empresa.'))
      } else {
        dispatch(toastActions.showToast('Email e/ou senha inválidos.'))
      }
    } finally {
      dispatch(authActions.setLoading(false))
    }
  },
  login: body => async (dispatch) => {
    dispatch(authActions.setLoading(true))
    dispatch(authActions.setTwoFactorLoading(true))
    try {
      const { email, password, ...rest } = body
      if (body['g-recaptcha-response']) {
        const hash = `Basic ${encode(`${email}:${password}`)}`
        const { data } = await API.login(hash, rest)
        if (data.code === 'WAITING_CODE') {
          dispatch(authActions.setTwoFactorLoading(false))
          dispatch(authActions.setTwoFactor(true))
          dispatch(toastActions.showToast(data.message))
        } else {
          dispatch(authActions.setTwoFactor(false))
          dispatch(authActions.setTwoFactorLoading(false))
          localStorage.setItem('token', data.token)
          localStorage.setItem('refreshToken', data.refreshToken)
          localStorage.setItem('user', JSON.stringify(data.user))
          localStorage.setItem('hyperflow-diagram-nodes', null)
          dispatch(userActions.setUser(data.user))
          dispatch(push('/bots'))
        }
      } else {
        dispatch(toastActions.showToast('Captcha obrigatório'))
      }

    } catch (error) {
      if (error?.response?.data?.code === "NO_ACCESS") {
        dispatch(toastActions.showToast('Sua conta foi desativada. Para mais informações entre em contato com o responsável em sua empresa.'))
      } else if (error?.response?.data?.code === "INVALID_CODE") {
        dispatch(toastActions.showToast(error?.response?.data?.message))
      } else if (error?.response?.data?.code === "INVALID_CODE_ATTEMPTS") {
        dispatch(authActions.setTwoFactorLoading(false))
        dispatch(authActions.setTwoFactor(false))
        dispatch(toastActions.showToast(error?.response?.data?.message))
        window.location.reload()
      } else {
        dispatch(toastActions.showToast(error?.response?.data?.message || 'Email e/ou senha inválidos.'))
      }
    } finally {
      dispatch(authActions.setLoading(false))
      dispatch(authActions.setTwoFactorLoading(false))
    }
  },
  authenticate: () => async (dispatch) => {
    const user = JSON.parse(localStorage.getItem('user'))
    const tenant = JSON.parse(sessionStorage.getItem('tenant') || localStorage.getItem('tenant'))
    tenant && dispatch(userActions.setTenant(tenant))
    dispatch(userActions.setUser(user))
    dispatch(userActions.me())
  },
  logout: body => async (dispatch) => {
    const whitelabel = getWhitelabel()
    localStorage.clear()
    dispatch(userActions.initialState())
    dispatch(environmentActions.initialState())
    dispatch(replace(`/?wl=${whitelabel}`))
  },
  getInviteByToken: token => async (dispatch) => {
    try {
      const { data } = await API.getInviteByToken(token)
      dispatch(authActions.setInvite(data))
    } catch (e) {
      dispatch(toastActions.showToast('Invite expired or not found'))
      dispatch(replace('/'))
    }
  },
  forgotPassword: body => async (dispatch) => {
    dispatch(authActions.setForgotLoading(true))
    try {
      await API.forgotEmail(body)
      dispatch(toastActions.showToast(`As instruções para o reset de senha foram enviadas para o email: ${body.email}`))
      dispatch(authActions.setForgotPassword(false))
    } catch (error) {
      if (error.response.status == 404) {
        dispatch(toastActions.showToast('Nenhum usuário encontrado para este email.'))
      } else if (error.response) {
        dispatch(toastActions.showToast(error.response.data.message))
      }
    } finally {
      dispatch(authActions.setForgotLoading(false))
    }
  },
  updatePassword: body => async (dispatch) => {
    dispatch(authActions.setLoading(true))
    try {
      await API.updatePassword(body)
      dispatch(toastActions.showToast('Senha alterada com sucesso'))
      dispatch(replace('/'))
      // const { email, password } = body
      // const hash = `Basic ${encode(`${email}:${password}`)}`
      // const { data } = await API.login(hash, body)
      // localStorage.setItem('token', data.token)
      // localStorage.setItem('refreshToken', data.refreshToken)
      // localStorage.setItem('user', JSON.stringify(data.user))
      // localStorage.setItem('hyperflow-diagram-nodes', null)
      // dispatch(userActions.setUser(data.user))
      // dispatch(push('/bots'))
    } catch (error) {
      if (error.response) {
        dispatch(toastActions.showToast('Link not existing or expired. Please try again.'))
      }
    } finally {
      dispatch(authActions.setLoading(false))
    }
  },
  acceptInvite: body => async (dispatch, getState) => {
    const { auth } = getState()
    const inviteToken = auth.invite.token
    dispatch(authActions.setLoading(true))
    try {
      await API.acceptInvite(inviteToken, body)
      dispatch(authActions.loginWithoutCaptcha(body))
    } catch (error) {
      if (error.response) {
        dispatch(toastActions.showToast(error.response.data.message))
      }
    } finally {
      dispatch(authActions.setLoading(false))
    }
  },
  getForgotByToken: token => async (dispatch) => {
    dispatch(authActions.setLoading(true))
    try {
      const { data } = await API.getForgotByToken(token)
      dispatch(authActions.setForgot(data))
    } catch (error) {
      if (error.response) {
        dispatch(toastActions.showToast('Link not existing or expired.'))
        dispatch(push(`/login`))
        //TODO Direcionar para NOT FOUND
      }
    } finally {
      dispatch(authActions.setLoading(false))
    }
  },
  refreshAuth: value => ({ type: actionTypes.SET_REFRESH, value }),
  setInvite: data => ({ type: actionTypes.SET_INVITE, data }),
  setLoading: value => ({ type: actionTypes.SET_LOADING, value }),
  setForgot: data => ({ type: actionTypes.SET_FORGOT, data }),
  setForgotLoading: value => ({ type: actionTypes.SET_FORGOT_LOADING, value }),
  setForgotPassword: value => ({ type: actionTypes.SET_FORGOT_PASSWORD, value }),
  setTwoFactor: value => ({ type: actionTypes.SET_TWO_FACTOR, value }),
  setTwoFactorLoading: value => ({ type: actionTypes.SET_TWO_FACTOR_LOADING, value })


}

export default authActions
