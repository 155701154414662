import { createMuiTheme } from '@material-ui/core/styles'
import { ptBR } from '@material-ui/core/locale'

export const createTheme = theme => createMuiTheme({
  ...theme,
  palette: {
    type: 'light',
    primary: {
      main: theme.primary,
      contrastText: '#FFF'
    },
    secondary: {
      main: theme.secondary,
      contrastText: '#FFF'
    },
    error: {
      main: theme.error
    },
    sand: {
      main: theme.sand
    },
    shell: {
      main: theme.shell
    },
    status: {
      danger: theme.danger
    },
    white: {
      main: theme.white
    },
    contrastThreshold: 1,
    tonalOffset: 0.2
  },
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: theme.secondary,
        fontSize: '20px'
      }
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        // backgroundColor: '#123123',
        // color: "white",
      }
    },
    MuiPickersDay: {
      day: {
        color: theme.secondary
      },
      isSelected: {
        '&:hover': {
          backgroundColor: theme.secondary
        },
        backgroundColor: theme.secondary
      },
      current: {
        color: theme.secondary
      }
    },
    MuiPickersModal: {
      dialogAction: {
        color: '#123123'
      }
    },
    MuiOutlinedInput: {
      root: {
        '&$focused $notchedOutline': {
          borderColor: theme.secondary,
          borderWidth: 1
        },
      }
    }
  }
}, ptBR)

export default theme => createTheme(theme)
