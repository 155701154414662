import API from 'api'
import * as S3 from 'aws-sdk/clients/s3'
import { push, replace } from 'react-router-redux'
import toastActions from 'states/toast/action'
import environmentActions from 'states/environment/action'
import broadcastActions from 'states/broadcast/action'
import channelActions from 'states/channel/action'
import entityActions from 'states/entity/action'
import dashboardActions from 'states/dashboard/action'
import eventActions from 'states/event/action'
import flowActions from 'states/flow/action'
import integrationActions from 'states/integration/action'
import intentActions from 'states/intent/action'
import nlpActions from 'states/nlp/action'
import transcriptorActions from 'states/transcriptor/action'

const albumBucketName = 'nexflow-assets'
const region = 'sa-east-1'

const s3 = new S3({
  apiVersion: '2006-03-01',
  region,
  secretAccessKey: '7lrdjI6XZsn7iDhB+ss9kgVcGw2fMgDcBS9fL8WT',
  accessKeyId: 'AKIAWG7FKT4V23IVM4V6',
  params: { Bucket: albumBucketName }
})

export const actionTypes = {
  GET_BOTS: 'BOT/GET_BOTS',
  GET_BOT_BY_ID: 'BOT/GET_BOT_BY_ID',
  SET_BOTS: 'BOT/SET_BOTS',
  SET_BOTS_PAGINATED: 'BOT/SET_BOTS_PAGINATED',
  SET_INVITES: 'BOT/SET_INVITES',
  SET_BOT: 'BOT/SET_BOT',
  OPEN_ADD_INVITE: 'BOT/OPEN_ADD_INVITE',
  SET_LOADING: 'BOT/SET_LOADING',
  SET_LOADING_PAGINATED: 'BOT/SET_LOADING_PAGINATED',
  SET_BOT_USERS: 'BOT/SET_BOT_USERS',
  SET_DIALOG_LOADING: 'BOT/SET_DIALOG_LOADING',
  OPEN_ADD_BOT: 'BOT/OPEN_ADD_BOT',
  INITIAL_STATE: 'BOT/INITIAL_STATE'
}

export const actions = {
  createBot: body => async (dispatch) => {
    dispatch(actions.setLoading(true))
    try {
      if (body.file) {
        body.picture = await actions.uploadFile(body.file)
        delete body.file
      }
      const { data } = await API.createBot(body)
      dispatch(actions.openAddBot(false))
      dispatch(actions.setBot(data))
      dispatch(push(`/bots/${data.index.name}`))
      dispatch(actions.getBots())
      dispatch(toastActions.showToast('Bot created successfully.'))
    } catch (error) {
      console.log(error)
    } finally {
      dispatch(actions.setLoading(false))
    }
  },
  uploadFile: async (file) => {
    const ext = file.name.split('.').pop()
    const { Location } = await s3.upload({
      Key: `bots/pictures/${new Date().toISOString()}.${ext}`,
      Body: file,
      ACL: 'public-read'
    }).promise()
    return Location
  },
  updateBot: body => async (dispatch, getState) => {
    const state = getState()
    const { bot } = state.bot
    dispatch(actions.setLoading(true))
    try {
      if (body.file) {
        body.picture = await actions.uploadFile(body.file)
        delete body.file
      }
      const { data } = await API.updateBot(bot._id, body)
      dispatch(actions.setBot(data))
      dispatch(actions.getBots())
      dispatch(toastActions.showToast('Bot updated successfully.'))
    } catch (error) {
      console.log(error)
    } finally {
      dispatch(actions.setLoading(false))
    }
  },
  getBots: (search = '') => async (dispatch) => {
    dispatch(actions.setLoading(true))
    try {
      const { data } = await API.getBots(search)
      dispatch(actions.setBotsPaginated(data))
    } catch (error) {

    } finally {
      dispatch(actions.setLoading(false))
    }
  },
  getBotsPaginated: (search = '', page = 1, pageSize = 30, sort = 'name') => async (dispatch, getState) => {
    dispatch(actions.setLoadingPaginated(true))
    try {
      const state = getState()
      const { botsPaginated } = state.bot
      const { data } = await API.getBotsPaginated(search, page, pageSize, sort)
      dispatch(actions.setBotsPaginated({
        ...data,
        rows: (page === 1 ? data.rows : [...botsPaginated, ...data.rows])
      }))
    } catch (error) {

    } finally {
      dispatch(actions.setLoadingPaginated(false))
    }
  },
  getBotById: id => async (dispatch) => {
    dispatch(actions.setLoading(true))
    try {
      const { data } = await API.getBotById(id)
      dispatch(actions.setBot(data))
    } catch (error) {
      dispatch(replace('/bots'))

    } finally {
      dispatch(actions.setLoading(false))
    }
  },
  inviteUser: body => async (dispatch, getState) => {
    const state = getState()
    const { bot } = state.bot
    dispatch(actions.setLoading(true))
    try {
      await API.inviteUser(bot._id, body)
      dispatch(toastActions.showToast('Convite enviado com sucesso'))
      dispatch(actions.getInvites())
      dispatch(actions.getBotUsers())

    } catch (error) {
      dispatch(actions.setLoading(false))
    }
  },
  removeInvite: inviteId => async (dispatch, getState) => {
    const state = getState()
    const { bot } = state.bot
    dispatch(actions.setLoading(true))
    try {
      await API.removeInvite(bot._id, inviteId)
      dispatch(toastActions.showToast('Invite removed successfully'))
      dispatch(actions.getInvites())
    } catch (error) {
      console.log(error)
    }
  },
  getInvites: (search = '', page = 0, pageSize = 10, sort) => async (dispatch, getState) => {
    const state = getState()
    const { bot } = state.bot
    dispatch(actions.setLoading(true))
    try {
      const { data } = await API.getInvites(bot._id, search, page + 1, pageSize, sort)
      dispatch(actions.setInvites(data))
    } catch (error) {
      console.log(error)
    } finally {
      dispatch(actions.setLoading(false))
    }
  },
  getBotUsers: (search = '', page = 0, pageSize = 10, sort) => async (dispatch, getState) => {
    const state = getState()
    const { bot } = state.bot
    dispatch(actions.setLoading(true))
    try {
      const { data } = await API.getBotUsers(bot._id, search, page + 1, pageSize, sort)
      dispatch(actions.setBotUsers(data))
    } catch (error) {
      console.log('TENHO ERRO', error)
    } finally {
      dispatch(actions.setLoading(false))
    }
  },
  removeBotUser: userId => async (dispatch, getState) => {
    const state = getState()
    const { bot } = state.bot
    dispatch(actions.setLoading(true))
    try {
      const { data } = await API.removeBotUser(bot._id, userId)
      dispatch(toastActions.showToast('User removed successfully'))
      dispatch(actions.getBotUsers())
    } catch (error) {
      console.log(error)
    } finally {
      dispatch(actions.setLoading(false))
    }
  },
  removeBot: bot => async (dispatch, getState) => {
    dispatch(actions.setLoading(true))
    try {
      await API.removeBot(bot._id)
      dispatch(actions.getBots())
      dispatch(toastActions.showToast('Bot removed successfully'))
    } catch (error) {
      console.log(error)
    } finally {
      dispatch(actions.setLoading(false))
    }
  },
  openAddBot: value => ({ type: actionTypes.OPEN_ADD_BOT, value }),
  openAddInvite: value => ({ type: actionTypes.OPEN_ADD_INVITE, value }),
  setBots: data => ({ type: actionTypes.SET_BOTS, data }),
  setBotsPaginated: data => ({ type: actionTypes.SET_BOTS_PAGINATED, data }),
  setInvites: data => ({ type: actionTypes.SET_INVITES, data }),
  setBotUsers: data => ({ type: actionTypes.SET_BOT_USERS, data }),
  setBot: data => async (dispatch) => {
    dispatch({ type: actionTypes.SET_BOT, data })
    if (data) {
      await dispatch(environmentActions.getEnvironments(data._id))
      dispatch(entityActions.getAllEntities())
    }
  },
  setLoading: value => ({ type: actionTypes.SET_LOADING, value }),
  setLoadingPaginated: value => ({ type: actionTypes.SET_LOADING_PAGINATED, value }),
  setDialogLoading: value => ({ type: actionTypes.SET_DIALOG_LOADING, value }),
  initialState: () => (dispatch) => {
    dispatch({ type: actionTypes.INITIAL_STATE })
    dispatch(environmentActions.initialState())
    dispatch(broadcastActions.initialState())
    dispatch(channelActions.initialState())
    dispatch(entityActions.initialState())
    dispatch(dashboardActions.initialState())
    dispatch(eventActions.initialState())
    dispatch(flowActions.initialState())
    dispatch(integrationActions.initialState())
    dispatch(intentActions.initialState())
    dispatch(nlpActions.initialState())
    dispatch(transcriptorActions.initialState())
  }
}

export default actions
