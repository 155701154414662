import { createReducer } from '../../reducers'
import { actionTypes } from './action'

export const initialState = () => ({
  loading: false,
  dialogLoading: false
})

export const reducer = {
  [actionTypes.SET_LOADING](state, { value }) {
    return { ...state, loading: value }
  },
  [actionTypes.SET_DIALOG_LOADING](state, { value }) {
    return { ...state, dialogLoading: value }
  },
  [actionTypes.INITIAL_STATE]() {
    return initialState()
  }
}

export default createReducer(initialState(), reducer)
