import { createReducer } from '../../reducers'
import { actionTypes } from './action'

export const initialState = () => ({
  loading: false,
  loadingAll: false,
  dialogLoading: false,
  entities: [],
  allEntities: [],
  entity: null,
  openAddEntity: false,
  openEditEntity: false
})

export const reducer = {
  [actionTypes.SET_LOADING](state, { value }) {
    return { ...state, loading: value }
  },
  [actionTypes.SET_LOADING_ALL](state, { value }) {
    return { ...state, loadingAll: value }
  },
  [actionTypes.SET_DIALOG_LOADING](state, { value }) {
    return { ...state, dialogLoading: value }
  },
  [actionTypes.SET_ENTITIES](state, { data }) {
    return { ...state, entities: data.rows, count: data.count }
  },
  [actionTypes.SET_ALL_ENTITIES](state, { data }) {
    return { ...state, allEntities: data.rows }
  },
  [actionTypes.SET_ENTITY](state, { data }) {
    return { ...state, entity: data }
  },
  [actionTypes.OPEN_ADD_ENTITY](state, { value }) {
    return { ...state, openAddEntity: value }
  },
  [actionTypes.OPEN_EDIT_ENTITY](state, { value }) {
    return { ...state, openEditEntity: value, entity: null }
  },
  [actionTypes.INITIAL_STATE]() {
    return initialState()
  }
}

export default createReducer(initialState(), reducer)
