import { injectGlobal, css } from 'styled-components'
import gutterSpace from 'utils/gutter-space'

const sizes = {
  giant: 1170,
  desktop: 992,
  tablet: 768,
  phone: 376
}

export const media = Object.keys(sizes).reduce((accumulator, label) => {
  const emSize = sizes[label] / 16
  accumulator[label] = (...args) => css`
    @media (max-width: ${emSize}em) {
      ${css(...args)}
    }
  `
  return accumulator
}, {})


export default props => injectGlobal`

  html, body {
    margin: 0;
    padding: 0;
    font-family: 'Open Sans', sans-serif;
    overflow-y: visible;
    width: 100%;
    height: 100%
  }

  #app{
    width: 100%;
    height: 100%
  }

  * {
    box-sizing: border-box;
    font-family: 'Open Sans', sans-serif ;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  input, textarea {
    color: ${props.primary};
  }

  *::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    opacity:0.4
  }
  *::-moz-placeholder { /* Firefox 19+ */
    opacity:0.4
  }
  *:-ms-input-placeholder { /* IE 10+ */
    opacity:0.4
  }
  *:-moz-placeholder { /* Firefox 18- */
    opacity:0.4
  }

  *::-webkit-scrollbar {
    transition: all .5s;
    width: 5px;
    height: 1px;
  }
  *::-webkit-scrollbar-track {
    background: transparent;
  }
  *::-webkit-scrollbar-thumb {
    background: #b3ada7;
  }

  .relative {
    position: relative;
  }
  .fullWidth {
    width: 100% !important;
  }
  .fullHeight {
    height: 100% !important;
  }
  .mh-auto {
    margin: 0 auto;
  }
  .overflow-y-hidden {
    overflow-y: hidden;
  }
  .overflow-y {
    overflow-y: auto;
  }
  .flex {
    display: flex;
  }
  .flex-column {
    display: flex;
    flex-direction: column;
  }
  .align-center {
    align-items: center;
  }
  .justify-center {
    justify-content: center;
  }
  .input-spacing {
    margin: 16px 0 8px;
  }
  .react-select-container .react-select__control {
    padding: 9px 2px;
    font-size: 1rem;
  }
  .react-select-container .react-select__menu {
    z-index: 2;
  }

  ${gutterSpace}

`
