import API from 'api'
import toastActions from 'states/toast/action'
import { push } from 'react-router-redux'

export const actionTypes = {
  SET_LOADING: 'FLOW/SET_LOADING',
  SET_PUBLISHING: 'FLOW/SET_PUBLISHING',
  SET_SAVING: 'FLOW/SET_SAVING',
  SET_DIALOG_LOADING: 'FLOW/SET_DIALOG_LOADING',
  SET_FLOWS: 'FLOW/SET_FLOWS',
  SET_TRANSMISSION_FLOWS: 'FLOW/SET_TRANSMISSION_FLOWS',
  SET_FLOW: 'FLOW/SET_FLOW',
  SET_LOADING_DOWNLOAD: 'FLOW/SET_LOADING_DOWNLOAD',
  SET_VALIDATION: 'FLOW/SET_VALIDATION',
  SET_VALIDATION_ERRORS: 'FLOW/SET_VALIDATION_ERRORS',
  SET_BUILD_INFO: 'FLOW/SET_BUILD_INFO',
  OPEN_ADD_FLOW: 'FLOW/OPEN_ADD_FLOW',
  OPEN_IMPORT_FLOW: 'FLOW/OPEN_IMPORT_FLOW',
  OPEN_BUILDS: 'FLOW/OPEN_BUILDS',
  SET_BUILDS: 'FLOW/SET_BUILDS',
  OPEN_PUBLISH: 'FLOW/OPEN_PUBLISH',
  OPEN_PUBLISH_MANY: 'FLOW/OPEN_PUBLISH_MANY',
  OPEN_PUBLISH_MANY_LOADING: 'FLOW/OPEN_PUBLISH_MANY_LOADING',
  SET_CLONE_FLOW: 'FLOW/SET_CLONE_FLOW',
  OPEN_EDIT_FLOW: 'FLOW/OPEN_EDIT_FLOW',
  SET_REPORT: 'FLOW/SET_REPORT',
  INITIAL_STATE: 'FLOW/INITIAL_STATE',
  SET_LOADING_FLOWS_VALIDATION: 'FLOW/SET_LOADING_FLOWS_VALIDATION',
  SET_SHOW_FORM_PUBLISH_MANY: 'FLOW/SET_SHOW_FORM_PUBLISH_MANY'
}

export const actions = {
  getFlows: (search = '', page = 0, limit = 10, sort = 'name') => async (dispatch, getState) => {
    const { environment, bot } = getState()
    const botId = bot.bot._id
    const environmentId = environment.environment._id
    dispatch(actions.setLoading(true))
    try {
      const { data } = await API.getFlows(botId, environmentId, search, page + 1, limit, sort)
      dispatch(actions.setFlows(data))
    } catch (error) {
    } finally {
      dispatch(actions.setLoading(false))
    }
  },
  getTransmissionFlows: (search = '', page = 0, limit = 10, sort = 'name') => async (dispatch, getState) => {
    const { environment, bot } = getState()
    const botId = bot.bot._id
    const environmentId = environment.environment._id
    dispatch(actions.setLoading(true))
    try {
      const { data } = await API.getTransmissionFlows(botId, environmentId, search, page + 1, limit, sort)
      dispatch(actions.setTransmissionFlows(data))
    } catch (error) {
    } finally {
      dispatch(actions.setLoading(false))
    }
  },
  exportExcel: () => async (dispatch, getState) => {
    const { environment, bot } = getState()
    const botId = bot.bot._id
    const environmentId = environment.environment._id
    dispatch(actions.setLoadingDownload(true))
    try {
      const { data } = await API.exportExcel(botId, environmentId)
      const url = window.URL.createObjectURL(new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      }))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'Flow Conversations')
      document.body.appendChild(link)
      link.click()
    } catch (error) {
      if (error.response) {
        dispatch(toastActions.showToast(error.response.data.message))
      }
    } finally {
      dispatch(actions.setLoadingDownload(false))
    }
  },
  setLoadingDownload: value => ({ type: actionTypes.SET_LOADING_DOWNLOAD, value }),
  addFlow: body => async (dispatch, getState) => {
    const { environment, bot } = getState()
    const botId = bot.bot._id
    const environmentId = environment.environment._id
    dispatch(actions.setDialogLoading(true))
    try {
      const { data } = await API.addFlow(botId, environmentId, body)
      dispatch(actions.openAddFlow(false))
      dispatch(actions.openImportFlow(false))
      dispatch(actions.getFlows())
      dispatch(push(`/bots/${bot.bot.index.name}/flow/${data.index.name}`))
      dispatch(toastActions.showToast('Flow added successfully.'))
    } catch (error) {
    } finally {
      dispatch(actions.setDialogLoading(false))
    }
  },
  addCloneFlow: (body, id) => async (dispatch, getState) => {
    const { environment, bot } = getState()
    const botId = bot.bot._id
    const environmentId = environment.environment._id
    dispatch(actions.setDialogLoading(true))
    try {
      const { data } = await API.cloneFlow(botId, environmentId, body, id)
      dispatch(actions.setCloneFlow(null))
      dispatch(actions.getFlows())
      dispatch(push(`/bots/${bot.bot.index.name}/flow/${data.index.name}`))
      dispatch(toastActions.showToast('Flow cloned successfully.'))
    } catch (error) {
    } finally {
      dispatch(actions.setDialogLoading(false))
    }
  },
  removeFlow: (id, search, page, limit, sort) => async (dispatch, getState) => {
    const { environment, bot } = getState()
    const botId = bot.bot._id
    const environmentId = environment.environment._id
    dispatch(actions.setLoading(true))
    try {
      const { data } = await API.removeFlow(botId, environmentId, id)
      dispatch(actions.getFlows(search, page, limit, sort))
      dispatch(toastActions.showToast('Flow removed successfully.'))
    } catch (error) {
    } finally {
      dispatch(actions.setLoading(false))
    }
  },
  getFlow: id => async (dispatch, getState) => {
    const { environment, bot } = getState()
    const botId = bot.bot._id
    const environmentId = environment.environment._id
    try {
      const { data } = await API.getFlow(botId, environmentId, id)
      dispatch(actions.setFlow(data))
      dispatch(actions.getReport(data._id))
    } catch (error) {
    } finally {
    }
  },
  getReport: id => async (dispatch, getState) => {
    const { environment } = getState()
    const environmentId = environment.environment._id
    try {
      const { data } = await API.getReport(environmentId, id)
      dispatch(actions.setReport(data))
    } catch (error) {
    } finally {
    }
  },
  updateFlow: (id, body) => async (dispatch, getState) => {
    const { environment, bot } = getState()
    const botId = bot.bot._id
    const environmentId = environment.environment._id
    try {
      dispatch(actions.setSaving(true))
      const { data } = await API.updateFlow(botId, environmentId, id, body)
      dispatch(actions.openEditFlow(false))
      dispatch(actions.getFlows())
      dispatch(actions.setFlow(data))
      dispatch(toastActions.showToast('Flow updated successfully.'))
    } catch (error) {

    } finally {
      dispatch(actions.setSaving(false))
    }
  },
  updateFlowDiagram: (id, body) => async (dispatch, getState) => {
    const { environment, bot } = getState()
    const botId = bot.bot._id
    const environmentId = environment.environment._id
    try {
      dispatch(actions.setSaving(true))
      const { data } = await API.updateFlowDiagram(botId, environmentId, id, body)
      // dispatch(actions.setFlow(data))
    } catch (error) {
    } finally {
      dispatch(actions.setSaving(false))
    }
  },
  publishFlow: (id, body) => async (dispatch, getState) => {
    const { bot, environment } = getState()
    const botId = bot.bot._id
    const environmentId = environment.environment._id
    try {
      dispatch(actions.setPublishing(true))
      await API.publishFlowV2(botId, environmentId, id, body)
      dispatch(actions.openPublish(false))
      dispatch(toastActions.showToast('Flow published successfully.'))
    } catch (error) {
      const message = error.response ?.data ?.message || 'Unexpected error'
      dispatch(toastActions.showToast(message))
    } finally {
      dispatch(actions.setPublishing(false))
    }
  },
  publishFlows: (flows, body) => async (dispatch, getState) => {
    const { bot, environment } = getState()
    const botId = bot.bot._id
    const environmentId = environment.environment._id
    let i = 0
    let toPublishFlows = flows.map(flow => ({ ...flow, loading: false, success: false, error: null }))

    const updateByIndex = (newItem, i) => toPublishFlows.map((item, index) => i === index ? newItem : item)

    dispatch(actions.openPublishMany(false))
    
    for (; i < toPublishFlows.length; i++) {
      const item = toPublishFlows[i]
      const id = item._id

      toPublishFlows = updateByIndex({ ...item, loading: true }, i)
      dispatch(actions.openPublishManyLoading({ flows: toPublishFlows, finished: false }))

      try {
        // if (i === 4) {
        //   await new Promise(resolve => setTimeout(resolve, 5000))
        //   throw new Error('oia o erro aqui')
        // } else { 
        //   await new Promise(resolve => setTimeout(resolve, 3000))
        // }
        await API.publishFlowV2(botId, environmentId, id, body)
        toPublishFlows = updateByIndex({ ...item, loading: false, success: true }, i)
      } catch (error) {
        const message = error.response ?.data ?.message || 'Unexpected error'
        toPublishFlows = updateByIndex({ ...item, loading: false, error: message }, i)
      }
      dispatch(actions.openPublishManyLoading({ flows: toPublishFlows, finished: false }))
    }

    dispatch(actions.openPublishManyLoading({ flows: toPublishFlows, finished: true }))
    dispatch(toastActions.showToast('Flows published successfully.'))
  },
  validateFlow: id => async (dispatch, getState) => {
    const { environment, bot } = getState()
    const botId = bot.bot._id
    const environmentId = environment.environment._id
    try {
      dispatch(actions.openPublish(true))
      dispatch(actions.setBuildInfo(false))
      dispatch(actions.setDialogLoading('validate'))
      const { data } = await API.validateFlow(botId, environmentId, id)
      const validationErrors = []
      Object.keys(data.nodes).forEach((key) => {
        if (data.nodes[key].showWarning) {
          validationErrors.push({
            name: data.nodes[key].name,
            errors: data.nodes[key].errors
          })
        }
      })
      dispatch(actions.setValidationErrors(validationErrors))
      dispatch(actions.setDialogLoading(false))
      dispatch(actions.setValidation(data))
    } catch (error) {
      const message = error.response ?.data ?.message || 'Unexpected error'
      dispatch(toastActions.showToast(message))
    } finally { }
  },
  validateFlows: flowsId => async (dispatch, getState) => {
    const { environment, bot } = getState()
    const botId = bot.bot._id
    const environmentId = environment.environment._id
    try {
      dispatch(actions.setShowFormPublishMany(false))
      dispatch(actions.setLoadingFlowsValidation(true))
      let validationErrors = []

      for (let i = 0; i < flowsId.length; i++) {
        const id = flowsId[i];
        const { data } = await API.validateFlow(botId, environmentId, id)
        validationErrors = []
        Object.keys(data.nodes).forEach((key) => {
          if (data.nodes[key].showWarning) {
            validationErrors.push({
              id,
              name: data.nodes[key].name,
              errors: data.nodes[key].errors
            })
          }
        })
        if (validationErrors.length) {
          break
        }
      }

      dispatch(actions.setValidationErrors(validationErrors))
      dispatch(actions.setLoadingFlowsValidation(false))
    } catch (error) {
      const message = error.response ?.data ?.message || 'Unexpected error'
      dispatch(toastActions.showToast(message))
    } finally { }
  },
  getBuilds: () => async (dispatch, getState) => {
    const { environment, bot, flow } = getState()
    const botId = bot.bot._id
    const environmentId = environment.environment._id
    const flowId = flow.flow._id
    try {
      dispatch(actions.setDialogLoading('builds'))
      const { data } = await API.getBuilds(botId, environmentId, flowId)
      dispatch(actions.setBuilds(data))
    } catch (error) {
      const message = error.response ?.data ?.message || 'Unexpected error'
      dispatch(toastActions.showToast(message))
    } finally {
      dispatch(actions.setDialogLoading(false))
    }
  },
  resetBuild: build => async (dispatch, getState) => {
    const { environment, bot, flow } = getState()
    const botId = bot.bot._id
    const environmentId = environment.environment._id
    const flowId = flow.flow._id
    try {
      dispatch(actions.setDialogLoading(build._id))
      const { data } = await API.resetBuild(botId, environmentId, flowId, build._id)
      dispatch(actions.setFlow({ ...data, changeBuild: true }))
      dispatch(actions.openBuilds(false))
    } catch (error) {
      const message = error.response ?.data ?.message || 'Unexpected error'
      dispatch(toastActions.showToast(message))
    } finally {
      dispatch(actions.setDialogLoading(false))
    }
  },
  setFlows: data => ({ type: actionTypes.SET_FLOWS, data }),
  setTransmissionFlows: data => ({ type: actionTypes.SET_TRANSMISSION_FLOWS, data }),
  setBuilds: data => ({ type: actionTypes.SET_BUILDS, data }),
  setFlow: data => ({ type: actionTypes.SET_FLOW, data }),
  setValidation: data => ({ type: actionTypes.SET_VALIDATION, data }),
  setBuildInfo: data => ({ type: actionTypes.SET_BUILD_INFO, data }),
  setValidationErrors: data => ({ type: actionTypes.SET_VALIDATION_ERRORS, data }),
  setReport: data => ({ type: actionTypes.SET_REPORT, data }),
  openAddFlow: value => ({ type: actionTypes.OPEN_ADD_FLOW, value }),
  openImportFlow: value => ({ type: actionTypes.OPEN_IMPORT_FLOW, value }),
  openBuilds: value => async (dispatch) => {
    value && dispatch(actions.getBuilds())
    !value && dispatch(actions.setBuilds({ rows: [] }))
    dispatch({ type: actionTypes.OPEN_BUILDS, value })
  },
  openPublish: value => ({ type: actionTypes.OPEN_PUBLISH, value }),
  openPublishMany: value => ({ type: actionTypes.OPEN_PUBLISH_MANY, value }),
  openPublishManyLoading: data => ({ type: actionTypes.OPEN_PUBLISH_MANY_LOADING, data }),
  setCloneFlow: value => ({ type: actionTypes.SET_CLONE_FLOW, value }),
  openEditFlow: value => ({ type: actionTypes.OPEN_EDIT_FLOW, value }),
  setLoading: value => ({ type: actionTypes.SET_LOADING, value }),
  setSaving: value => ({ type: actionTypes.SET_SAVING, value }),
  setPublishing: value => ({ type: actionTypes.SET_PUBLISHING, value }),
  setDialogLoading: value => ({ type: actionTypes.SET_DIALOG_LOADING, value }),
  setLoadingFlowsValidation: value => ({ type: actionTypes.SET_LOADING_FLOWS_VALIDATION, value }),
  setShowFormPublishMany: value => ({ type: actionTypes.SET_SHOW_FORM_PUBLISH_MANY, value }),
  initialState: () => ({ type: actionTypes.INITIAL_STATE })
}

export default actions
