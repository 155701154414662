import { createReducer } from '../../reducers'
import { actionTypes } from './action'

export const initialState = {
    message: null,
    duration: 3000
}

export const reducer = {
    [actionTypes.SHOW_TOAST](state, { data }) {
        return { ...state, ...data }
    }
}

export default createReducer(initialState, reducer)
