import { createReducer } from '../../reducers'
import { actionTypes } from './action'

export const initialState = () => ({
  openAddChannel: false,
  selectedChannel: null,
  dialogLoading: false,
  channels: [],
  count: 0,
  loading: false
})

export const reducer = {
  [actionTypes.SET_LOADING](state, { value }) {
    return { ...state, loading: value }
  },
  [actionTypes.OPEN_ADD_CHANNEL](state, { value, selectedChannel }) {
    return { ...state, openAddChannel: value, selectedChannel }
  },
  [actionTypes.SET_DIALOG_LOADING](state, { value }) {
    return { ...state, dialogLoading: value }
  },
  [actionTypes.SET_CHANNELS](state, { data }) {
    return { ...state, channels: data.rows, count: data.count }
  },
  [actionTypes.INITIAL_STATE]() {
    return initialState()
  }
}

export default createReducer(initialState(), reducer)
