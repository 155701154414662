import theme from '.'

export const whiteLabels = {
  recrutei: {
    name: 'Recrutei',
    title: 'Recrutei',
    pageTitle: 'Recrutei',
    subtitle: 'Automatização em Recrutamento e Seleção',
    logo: require('assets/img/recrutei.png')
  },
  getbots: {
    name: 'Getbots',
    title: 'Getbots',
    pageTitle: 'Getbots',
    subtitle: 'Chatbots customizados e de alta escalabilidade',
    logo: require('assets/img/getbots.png'),
    loginLogo: require('assets/img/getbots2.png')
  },
  projetas: {
    name: 'Projetas',
    title: 'Projetas',
    pageTitle: 'Projetas',
    subtitle: 'Chatbots customizados e de alta escalabilidade',
    logo: require('assets/img/projetas.png'),
  },
  ezchatbot: {
    name: 'EZ Chatbot',
    title: 'EZ Chatbot',
    pageTitle: 'EZ Chatbot - Admin',
    subtitle: 'Chatbots customizados e de alta escalabilidade',
    primary: '#388FCA',
    secondary: '#00295E',
    logo: require('assets/img/ezchatnovo.svg'),
    login: {
      background: require('assets/img/ezChat/login.png'),
    }
  },
  comolatti: {
    name: 'Grupo Comolatti',
    title: 'Grupo Comolatti',
    pageTitle: 'Comolatti - Admin',
    subtitle: 'Chatbots customizados e de alta escalabilidade',
    primary: '#00a53f',
    secondary: '#4CC078',
    logo: require('assets/img/comolatti.svg'),
    login: {
      background: require('assets/img/comolatti.svg'),
    }
  },
  schatbot: {
    name: 'SChat',
    title: 'SChat',
    pageTitle: 'SChat - Admin',
    subtitle: 'Chatbots customizados e de alta escalabilidade',
    logo: require('assets/img/schat1.png'),
    primary: '#051836',
    secondary: '#00295E'
  },
  dendron: {
    name: 'Supernova',
    title: 'Supernova',
    pageTitle: 'Supernova - Admin',
    subtitle: 'Chatbots customizados e de alta escalabilidade',
    primary: '#68a242',
    secondary: '#4a33a0',
    logo: require('assets/img/dendron.png')
  },
  talkershop: {
    name: 'Talker Shop',
    title: 'Talker Shop',
    pageTitle: 'Talker Shop',
    subtitle: 'Chatbots para ecommerce. Da um Talker',
    logo: require('assets/img/talkershop.png'),
    primary: '#D8385C',
    secondary: '#000'
  },
  makecall: {
    name: 'Makecall',
    title: 'Makecall',
    pageTitle: 'Makecall',
    subtitle: 'Automatizando seu atendimento',
    logo: require('assets/img/makecall.png')
  },
  nexFlow: {
    name: 'HyperFlow',
    title: 'HyperFlow - Bots e automações',
    subtitle: 'Automating in a simple way',
    pageTitle: 'HyperFlow',
    logo: require('assets/img/logo2.svg'),
    primary: '#388FCA',
    secondary: '#00295E'
  },
  ebee: {
    name: 'Ebee',
    title: 'Ebee',
    pageTitle: 'Ebee',
    subtitle: 'Chatbots para negócios',
    logo: require('assets/img/ebee.png')
  }
}

export default {
  init: whiteLabel => Object.assign(theme, whiteLabels[whiteLabel])
}
